
import './App.css';
import './assets/styles/Customized.css';
import * as React from 'react';
import './assets/styles/Customized.css';
import './assets/styles/Theme.css';
import { useDispatch } from 'react-redux';
import ProfileImage from './assets/images/Avtaar.png';
import { setProfileImage } from './features/commonDataSlice/commonSlice';
import DashboardKM from './pages/KnowledgeManagement/VideoLibrary';
import { useEffect, useState } from 'react';
import { checkCallback, removeUserToken } from './_helper/secureToken';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PrivateComponent } from './_services/PrivateComponent';
import Logout from './pages/Logout';
import AppTour from './Apptour';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
const windowObject = window;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function App() {
  const theme = useTheme();
  const [component, setComponent] = useState([]);
  useEffect(() => {
    setComponent(PrivateComponent());
  }, []);
  let query = useQuery();
  let authToken = query.get("token");
  let appToken = query.get("app");
  let authType = query.get("authType");
  let callback = query.get("callback");
  let ssoToken = query.get("ssoToken");
  let location = useLocation();
  let loginauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/login?callback=${window.location.href}`;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProfileImage(ProfileImage))
  }, []);
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* {isXs || isSm ? null: <AppTour />} */}

      <Routes>

        {component.map((u, i) =>
          checkCallback(authToken, appToken, authType, callback, ssoToken) ?
            <Route key={i} path={u.url} element={u.page} />
            :
            (window.location.assign(loginauthenticationUrl))
        )}
        <Route path="/logout" element={<Logout />} />


      </Routes>
    </>)
}

export default App;



