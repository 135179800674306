import React from "react";
import App from "./App";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

function AppWrapper(props) {
    return (
        <Router>
            <App />
        </Router>
    );
}


export default AppWrapper;
