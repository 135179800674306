import React from 'react'
import { Box, Grid, Button, Stack, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../../../../features/drawers/drawerSlice';
import Close from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import DescriptionIcon from '@mui/icons-material/Description';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Details from './UploadDrawerPage/Details';
import Advance from './UploadDrawerPage/Advance';
import VideoSettings from './UploadDrawerPage/VideoSetting';
import MyContext from '../../../../../pages/KnowledgeManagement/Mycontext';


const UploadDrawer = ({context = MyContext}) => {

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer());
    };

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: { sm: '48rem',xs:'100%',lg:'62rem' } }}>
            <Box>
                <Stack sx={{ backgroundColor: "#DBEAFE", px: 2 }}>
                    <Box>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Upload Video</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ height: '80vh', overflow: 'auto', px: 2 }}>
                <Box>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab icon={<UploadFileIcon />} iconPosition="start" label="Details" value="1"  sx={{minHeight:'0px !important'}}/>
                                <Tab icon={<DescriptionIcon />} iconPosition="start" label="Advance" value="2"  sx={{minHeight:'0px !important'}}/>
                                {/* <Tab icon={<PlayCircleOutlinedIcon />} iconPosition="start" label="Video Settings" value="3"  sx={{minHeight:'0px !important'}}/> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Details context={context} /></TabPanel>
                        <TabPanel value="2"><Advance/></TabPanel>
                        <TabPanel value="3"><VideoSettings /></TabPanel>
                    </TabContext>
                </Box>
            </Box>
            {/* <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '2rem', height: '4rem' }}>
                <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
                    <Button variant="contained" size="small">SUBMIT</Button>
                    <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
                </Stack>
            </Box> */}
        </Box>
    )
}

export default UploadDrawer