import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2563EB',

    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000000',
        fontWeight: '400',
        boxShadow: '-1px 0px 6px #aaa',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #2563EB',
        borderRadius: 2,
    },
}));
const DashboardHeading = (props) => {
    const { title, numItems } = props;

    const breadcrumbStyle = {
        color: '#475569',
        width: '200px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }

    return (
        <Box>
            <Typography variant="h6" sx={title.title > 12 ? breadcrumbStyle : { color: '#475569' }} fontWeight='var(--font-weight-5)'>
                <Box sx={{ alignSelf: 'center' }}>
                    <HtmlTooltip title={title} arrow>
                        <Box sx={{width: 'fit-content'}}>
                            {title.length > 20 ? title.slice(0, 20) + '.....' : title}{typeof numItems == 'number' ? '(' + numItems + ')' : ''}
                        </Box>
                    </HtmlTooltip>
                </Box>
            </Typography>
        </Box>
    );
};

export default DashboardHeading;
