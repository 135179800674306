import React from "react";
// import Home from "../pages/Home";
import DashboardKM from "../pages/KnowledgeManagement/VideoLibrary";
import Sidebar from "../layouts/sidebar/Sidebar";
import Footer from "../layouts/footer/Footer";
import ThemeProvider from "../theme";
import Pinned from "../pages/KnowledgeManagement/Pinned";
import Playlist from "../pages/KnowledgeManagement/Playlist";
import TagsCloud from "../pages/KnowledgeManagement/TagsCloud";
import RecentFiles from "../pages/KnowledgeManagement/RecentFiles";
import Trash from "../pages/KnowledgeManagement/Trash";
import TagAttachedFolder from "../pages/KnowledgeManagement/TagAttachedFolder";
import ViewRecommendedVideo from "../components/drawerBody/Tstream/FileLibrary/ViewRecommendedVideo";
import ViewVideo from "../components/drawerBody/Tstream/FileLibrary/ViewVideo";

const windowObject = window;

export function PrivateComponent() {
  let response = [
    {
      url: "/",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <DashboardKM />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/folder/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <DashboardKM />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/tags",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <TagsCloud />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/pinned",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Pinned />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/playlist",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Playlist />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/recentfile",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <RecentFiles />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/trash",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Trash />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/tags-folder/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <TagAttachedFolder />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/ViewRecommendedVideo",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <ViewRecommendedVideo />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/ViewVideo",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <ViewVideo />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
  ];
  return response;
}
