
const throttle = () => {

    let self = {};
    self.started = false;
    self.timer = false;

    self.throttleStart = (fn,wait) => {
        // let timerId = null;
      
        return function (...args) {
          if (!self.started) {
            fn(...args);
            self.started = setTimeout(() => {
                self.started = null;
            }, wait);
          }
        };
    }

    self.throttle = (fn, wait) => {
        console.log({started: self.timer})
        if(!self.timer){
            self.timer = self.throttleStart(fn, wait);
        }
        console.log("Testing",{fn,wait,started: self.timer})
        self.timer();
    }

    self.debounce = (fn, wait) => {
        let timerId = null;
      
        return function (...args) {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            fn(...args);
          }, wait);
        };
    }

    return self;
}

export default throttle;