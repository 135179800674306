import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, ButtonGroup, Typography, Card, CardContent, CardMedia, CardActionArea, Menu, MenuItem, Fab } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApiService from '../../utils/ApiService';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AllVideoGrid from './AllGridView';
import AllGridView from '../KnowledgeManagement/AllGridView';
import MycontextChild from './MycontextChild';
import checkboxHelper from './checkboxHelper';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import { useSelector, useDispatch } from 'react-redux';
import PageName from '../../utils/getPageName';
import SharedDialog from '../../features/dialogBox/dialogBox';
import SharedDrawer from '../../features/drawers/Drawer';
import UploadDrawer from '../../components/drawerBody/Tstream/FileLibrary/StreamDrawer/UploadDrawer';
import DeleteDialog from '../KnowledgeManagement/DialogKM/DeleteDialog';
import RenameDialog from '../KnowledgeManagement/DialogKM/RenameDialog';
import { openDrawer, closeDrawer } from "../../features/drawers/drawerSlice";
import TransitionsSnackbar from './Constantfile/Snackbar';

const RecommendedVideo = ({ getRecommandedVideo }) => {
  ///////*****Video Menu******/
  const userDetail = useSelector((state) => state?.user?.userListName)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedItems, setSelectedItems] = useState(new Set([]));
  console.log("a1",selectedItems)
  const [checkedData, setCheckedData] = useState();
  // const [dashboardList, setDashboardList] = useState([]);
  const [checkboxGridHelper, setCheckboxGridHelper] = useState(
    checkboxHelper()
  );
  const [dynamicDeleteMessage, setDynamicDeleteMessage] = useState('');
  const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
  // console.log('a1',selectedId,setSelectedId)
  const dispatch = useDispatch();

  const openDialogPublish = (id) => {
    setAnchorEl(null);
    dispatch(openDialog(id));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  ///////*****End Video Menu******/

  ///////*****Api Recommended video******/
  const individualAPI = 'recommendedVideo';
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
  const dataParams = {
    related: ['streamCategory','streamSubcategory','modifyBy']
}
useEffect(() => {
    ApiService.get(individualAPI, dataParams)
      .then((recommendedVideos) => {
        if (methodsAndState.editFlag?.value === "create") {
          setRecommendedVideos(recommendedVideos.data.data.slice(0, 4));
        } else {
          // Assuming getRecommandedVideo is some function to process the data
          getRecommandedVideo(recommendedVideos.data.data);
          setRecommendedVideos(recommendedVideos.data.data.slice(0, 4));
        }
        console.log("Fetched recommended videos:", recommendedVideos.data.data);
      })
      .catch((error) => {
        console.error("Error fetching recommended videos:", error);
      });
}, []); 
  // useEffect(() => {
  //   ApiService.get(individualAPI,dataParams)
  //     .then((recommendedVideos) => {
  //       if (methodsAndState.editFlag?.value === "create") {
  //         getRecommandedVideo(recommendedVideos.data.data);
  //         setRecommendedVideos(recommendedVideos.data.data.slice(0, 4));
  //     } else {
  //       // let updateData = {...individualAPI}
  //       // updateData['modifyBy'] = {id: userDetail?.userId, firstName: userDetail?.userName, lastName: userDetail?.lastName};
  //       getRecommandedVideo(recommendedVideos.data.data);
  //       setRecommendedVideos(recommendedVideos.data.data.slice(0, 4)); 
  //     }
  //       console.log("ke", {recommendedVideos,recommendedVideo: recommendedVideos.data.data.slice(0, 4)})
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);
  ///////***** End Api Recommended video******/

  //   const updateMetaData = (count) => {
  //     var updatedMetaData = { ...metaData, total: metaData.total + count };
  //     setMetaData(updatedMetaData);
  // }
  // const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
  const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
  // const [selectedItems, setSelectedItems] = useState(new Set([]));
  const [selectedList, setSelectedList] = useState([]);
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  // const [checkedData, setCheckedData] = useState();
  // const [filterOn, setFilterOn] = useState(false);
  // const [checkboxGridHelper, setCheckboxGridHelper] = useState(
  //     checkboxHelper()
  // );
  // const [dashboardList, setDashboardList] = useState([]);
  // const [dashboardListMaster, setDashboardListMaster] = useState([]);
  const [lastModified, setLastModified] = React.useState('');

  const handleModifiedChange = (event) => {
    setLastModified(event.target.value);
  };
  const openDrawerPublish = (id) => {
    console.log("Are you sure??", {id})
    if (id == "editRecommandedFile") {
      setEditFlag({ flag: false, value: "edit" })
    } else {
      setEditFlag({ flag: true, value: "create" })
    }
    setAnchorEl(null);
    dispatch(openDrawer(id));
    // setSelectedItems(new Set([]))
  }

  // const [dashboardList, setDashboardList] = useState([]);
  // const [dashboardListMaster, setDashboardListMaster] = useState([]);
  const [hardRefresh, setHardRefresh] = useState(true);
  const [metaData, setMetaData] = useState();
  
const editNewVideo = (video, isRecommanded) => {
    let tempDashboardList = [...recommendedVideos];
    const index = tempDashboardList.findIndex(item => item.id === video.id);
    console.log("t1",index)
    if (index !== -1) { 
        const updatedVideo = {
            ...video,
            isUpdated: true 
        };
        tempDashboardList.splice(index, 1, updatedVideo); 
        setRecommendedVideos(tempDashboardList);
        // setDashboardListMaster([updatedVideo, ...dashboardListMaster]); 
        console.log("Roar", { tempDashboardList });
        if (isRecommanded) {
            setHardRefresh(false);
        }
        console.log({ updatedVideo, isRecommanded });
    } else {
        console.error("Video not found in dashboardList");
    }
}

  const methodsAndState = {
    dashboardList: recommendedVideos,
    // checkboxGridHelper,
    handleModifiedChange,
    isRecommanded: true,
    setSelectedItems,
    selectedItems,
    openDialogPublish,
    setEditFlag,
    editNewVideo,
    setDashboardList: setRecommendedVideos,
    editFlag,
    // setDashboardList,
    // setSelectedItems,
    // selectedItems,
    setSelectedId,
    selectedId,
    setSelectedList,
    selectedList,
    setDynamicDeleteMessage,
    dynamicDeleteMessage,
    // updateMetaData,
    // setMetaData, metaData,
    setSnackBar,
    snackBar,
    // onChangeSelectCategory,
    // selectRef,
    setCheckedData,
    checkedData,
    headerCheckStatus,
    checkboxGridHelper,
    setHeaderCheckStatus,
    page: PageName.RecommendedVideos,
    openDrawerPublish
  }


  return (
    <MycontextChild.Provider value={methodsAndState}>
      <Box>
        <Box>
          <AllGridView checkboxRequied={false} page={PageName.RecommendedVideos} context={MycontextChild} />
        </Box>
      </Box>
      <SharedDrawer id="editRecommandedFile">
        {/* <FileDashboardKM /> */}
        <UploadDrawer context={MycontextChild} />
      </SharedDrawer>
      <SharedDialog id="renameRecommandedDialog">
        <RenameDialog context={MycontextChild} />
      </SharedDialog>
      <SharedDialog id="deleteRecommandedDialog">
        <DeleteDialog context={MycontextChild} />
      </SharedDialog>
      <TransitionsSnackbar context={MycontextChild} />
    </MycontextChild.Provider>
  )
}

export default RecommendedVideo