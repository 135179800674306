import React, { useRef, useEffect, useState } from 'react'
// import dragula from 'dragula';
import Chip from '@mui/material/Chip';
import { Box, Card, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuBar from '../../../../pages/KnowledgeManagement/menuBar';
import MyContext from '../../../../pages/KnowledgeManagement/Mycontext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Advertisment from '../../../../assets/images/Tstream/advertisment.jpg';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useNavigate } from 'react-router-dom';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
// import InputBase from '@mui/material/InputBase';
// import Fab from '@mui/material/Fab';
// import PauseIcon from '@mui/icons-material/Pause';
// import { PlayArrow as PlayArrowIcon } from '../utils/AllImportsHelper';
// import VideoThunbnailImg from '../assets/images/videoThunbnailImg.png'
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import ApiService from '../../../../utils/ApiService';
import checkboxHelper from '../../../../pages/KnowledgeManagement/checkboxHelper';
import { useLocation } from 'react-router-dom';
import encriptionHelper from '../../../../utils/encriptionHelper';
import DynamicSnackbar from '../../../../features/snackbar/Snackbar';
import DownloadBar from '../../../../features/downloadBar/DownloadBar';

const ViewVideos = () => {

    const coinsWeHave = 48;
    const location = useLocation();

    const searchParams = encriptionHelper().decryptURLParams(window.location.search.substring(1).split("&")[0].split("=")[1]);
    console.log({ searchParams })
    console.log({ location })
    //search bar start/////////////
    // const Search = styled('div')(({ theme }) => ({
    //   position: 'relative',
    //   borderRadius: '10px',
    //   backgroundColor: '#FFFFFF',
    //   width: '100%',
    //   [theme.breakpoints.up('sm')]: {
    //     width: 'auto',
    //   },
    // }));

    // const SearchIconWrapper = styled('div')(({ theme }) => ({
    //   padding: theme.spacing(0, 2),
    //   height: '100%',
    //   position: 'absolute',
    //   pointerEvents: 'none',
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // }));

    // const StyledInputBase = styled(InputBase)(({ theme }) => ({
    //   color: 'inherit',
    //   '& .MuiInputBase-input': {
    //     padding: theme.spacing(1, 1, 1, 0),
    //     // vertical padding + font size from searchIcon
    //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    //     transition: theme.transitions.create('width'),
    //     width: '100%',
    //     [theme.breakpoints.up('sm')]: {
    //       width: '12ch',
    //       '&:focus': {
    //         width: '20ch',
    //       },
    //     },
    //   },
    // }));
    //////////

    //////////////////  Accordian start ///////////

    const [expanded, setExpanded] = React.useState('panel2');
    const [selectedVideo, setSelectedVideo] = React.useState(location?.state?.currentVideo || location?.state?.playlist?.[0] || {});
    // console.log('a1',location.state.currentVideo)
    console.log('a1', location?.state?.currentVideo);
    const [videoUpdate, setVideoUpdate] = useState([]);
    const [category, setCategory] = useState([]);
    const [videoListByCategory, setVideoListByCategory] = useState(location?.state?.playlist || []);
    const [pageName, setPageName] = useState(location?.state?.playListName || searchParams?.playListName || location?.state?.page || searchParams.page);

    console.log({ searchParams, videoListByCategory })
    // const [selectedVideo, setSelectedVideo] = useState(null);
    // const methodsAndState = useContext(MyContext);
    // useEffect(() => {
    //     ApiService.get('particularPlaylist', {}).then(res => {
    //         // setLoading(false)
    //         let data = res.data.data
    //         console.log({ res, data });
    //         if (data) {
    //             setVideoUpdate(data);
    //             // let reverseData = _.orderBy(data, ['type'], ['asc']);
    //             // setDashboardList(reverseData)
    //         }
    //     }).catch((error) => {
    //         // setLoading(false)
    //         console.log(error);
    //     })
    // const individualAPI = 'particularPlaylist';
    const [particularVideos, setparticularVideos] = useState([]);
    // useEffect(() => {
    // ApiService.get(individualAPI, {})
    //     .then((particularVideos) => {
    //         setparticularVideos(particularVideos.data.data)
    //         console.log("a1", particularVideos)
    //         if (particularVideos) {

    //         }
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
    // }, []);

    // ApiService.get('streamCategory', {}).then(res => {
    //     // setLoading(false)
    //     let data = res.data.data
    //     console.log({ res, data });
    //     if (data) {
    //         setCategory(data);
    //         // let reverseData = _.orderBy(data, ['type'], ['asc']);
    //         // setDashboardList(reverseData)
    //     }
    // }).catch((error) => {
    //     // setLoading(false)
    //     console.log(error);
    // })

    // }, [])

    // useEffect(() => {
    //     if (videoUpdate.length && category.length) {
    //         let videoListByCategory = category.map(cat => {
    //             cat.videos = videoUpdate.filter(video => video.categoryId == cat.id);
    //             return cat;
    //         })

    //         console.log("videoListByCategory", { videoListByCategory });
    //         setVideoListByCategory(videoListByCategory);
    //     }
    // }, [videoUpdate, category])

    useEffect(() => {
        if (!location.state) {
            if (searchParams?.playlistId) {
                ApiService.get(searchParams.indivisualApi, {}, searchParams?.playlistId).then((res) => {
                    console.log({ data: res.data.data });
                    setVideoListByCategory(res.data.data.videoPlaylist)
                })
            } else {
                ApiService.get(searchParams.indivisualApi).then((res) => {
                    console.log({ data: res.data.data });
                    setVideoListByCategory(res.data.data)
                })
            }
            ApiService.get('particularPlaylist', {}, searchParams?.currentVideoId).then((res) => {
                console.log({ data: res.data.data });
                setSelectedVideo(res.data.data)
                // console.log('a1',setVideoListByCategory(res.data.data))
            })
        }
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    /////////////////  Accordian End  ////////////
    //////item style //////////
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));
    /////////End///////////////////////

    ////videos panel//
    // const [isPlaying, setIsPlaying] = useState(false);
    // const [showPlayButton, setShowPlayButton] = useState(false);
    //change it to true for the fab to show up
    // const videoRef = useRef(null);


    const handlePlay = () => {
        if (videoRef.current) {
            // Check if the video has already been loaded
            if (!videoRef.current.hasAttribute('data-loaded')) {
                // If not loaded, set the attribute and play the video
                videoRef.current.setAttribute('data-loaded', 'true');
                videoRef.current.play();
            } else {
                // If already loaded, just play the video
                videoRef.current.play();
            }
        }
    };

    // function handleMouseEnter() {
    //   setShowPlayButton(true);
    // }

    // function handleMouseLeave() {
    //   setShowPlayButton(false);
    // }

    // function handlePlayClick() {
    //   if (isPlaying) {
    //     videoRef.current.pause();
    //     setIsPlaying(false);
    //   }
    //   else {
    //     videoRef.current.play();  
    //     setIsPlaying(true);
    //   }
    // }
    ////end////




    //   const [currentThumb, clickedThumb] = useState(VideoThunbnailImg);
    //   const [currentVideo, clickedVideo] = useState(introVideo);

    function clicked(index) {
        console.log(index)
        // var clickedSourceVideo = mandatoryData[index].videos;
        // var clickedSourceImage = mandatoryData[index].video;
        let clr = '#A8CFFB'
        setSelectedVideo(clr)
        // console.log(clickedSourceVideo)
        // console.log(clickedSourceImage)
        // clickedVideo(clickedSourceVideo);
        // clickedThumb(clickedSourceImage);
    }
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(
        checkboxHelper()
    );


    const handleCardClick = (video) => {
        setSelectedVideo(video);
    };

    const isVideoSelected = (video) => {
        return selectedVideo && selectedVideo.id === video.id;
    };

    const methodsAndState = {
        setSelectedItems,
        selectedItems,
        page: pageName,
        dashboardList: videoListByCategory,
        playlistId: searchParams?.playlistId
    }
    const navigate = useNavigate();

    const goBack = () => {
        // Go back to the previous page
        navigate(-1);
    };

    const selectionCheckboxClear = (data, noClear) => {
        if (!noClear) {
            checkboxGridHelper.clearSelection()
            checkboxGridHelper.checkboxListener(data.id);
        }
        selectedItems.clear()
        selectedItems.add(data.id)
    }

    // const [videoDuration, setVideoDuration] = useState(null);
    // const videoRef = useRef();

    // useEffect(() => {
    //     if (videoRef.current) {
    //         const updateDuration = () => {
    //             const duration = videoRef.current.duration;
    //             if (!isNaN(duration)) {
    //                 setVideoDuration(duration);
    //             }
    //         };
    //         videoRef.current.addEventListener('loadedmetadata', updateDuration);
    //         return () => {
    //             videoRef.current?.removeEventListener('loadedmetadata', updateDuration);
    //         };
    //     }
    // }, []);

    // function formatTime(seconds) {
    //     if (seconds < 0) {
    //         throw new Error("Invalid duration: duration must be a non-negative number.");
    //     }

    //     const hours = Math.floor(seconds / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const secondsRemainder = Math.floor(seconds % 60);

    //     const timeString = hours > 0
    //         ? `${hours}:${minutes.toString().padStart(2, '0')}m:${secondsRemainder.toString().padStart(2, '0')}s`
    //         : `${minutes}m:${secondsRemainder.toString().padStart(2, '0')}s`;

    //     return timeString;
    // }

    const [videoDuration, setVideoDuration] = useState(null);
    const videoRef = useRef();

    useEffect(() => {
        if (videoRef.current && selectedVideo && selectedVideo.videoUrl) {
            const videoElement = videoRef.current;
            const updateDuration = () => {
                const duration = videoElement.duration;
                if (!isNaN(duration)) {
                    setVideoDuration(duration);
                }
            };

            videoElement.addEventListener('loadedmetadata', updateDuration);

            return () => {
                videoElement.removeEventListener('loadedmetadata', updateDuration);
            };
        }
    }, [selectedVideo]);

    function formatTime(seconds) {
        if (seconds < 0) {
            throw new Error("Invalid duration: duration must be a non-negative number.");
        }

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secondsRemainder = Math.floor(seconds % 60);

        const timeString = hours > 0
            ? `${hours}:${minutes.toString().padStart(2, '0')}m:${secondsRemainder.toString().padStart(2, '0')}s`
            : `${minutes}m:${secondsRemainder.toString().padStart(2, '0')}s`;

        return timeString;
    }







    return (
        <MyContext.Provider value={methodsAndState}>
            <Box>
                {/* {particularVideos.map((particularVideo, index) => ( */}
                <Grid container spacing={2} pb={8}>
                    <Grid item xs={12} md={7} lg={8} sx={{ minHeight: '100vh', height: 'inherit' }}>
                        <Box sx={{ display: 'flex', p: '1', mt: 2 }} onClick={goBack}>
                            <Button variant="text" startIcon={<ArrowBackIosOutlinedIcon />}>
                                <Typography variant="h6" component="h2" color='black'>
                                    {pageName}
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{ height: 1, mt: 2 }}>
                            <Box sx={{ height: 1 }}>
                                <Stack sx={{ height: 'inherit', '& .MuiPaper-elevation': { height: 'inherit' } }}>
                                    <Item sx={{ borderRadius: 'var(--border-radius-none)', boxShadow: 'var(--box-shadow-none)', p: '1rem' }}>
                                        <Stack>
                                            <Box sx={{ width: 1 }}> {/* mui replacement for width:100% */}
                                                <Box sx={{ position: 'relative' }}>
                                                    <Box sx={{ minWidth: 300, flexGrow: 1 }}>
                                                        <video
                                                            controls
                                                            ref={videoRef}
                                                            src={selectedVideo && selectedVideo.videoUrl}
                                                            className="video-tag"
                                                            preload="metadata"
                                                            onPlay={handlePlay}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography color="var(--color-black)" variant="h6" fontWeight="var(--font-weight-7)" gutterBottom>
                                                        {selectedVideo.name}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box>
                                                        <Typography variant='body2' color='#607D8B'>{selectedVideo?.streamCategory?.name}</Typography>
                                                    </Box>
                                                    <Divider sx={{ mr: 'var(--margin-divider)', ml: 'var(--margin-divider)' }} orientation="vertical" flexItem />
                                                    <Box>
                                                        <Typography variant='body2' color='#607D8B'>
                                                            {videoDuration !== null ? formatTime(videoDuration) : "Loading..."}
                                                        </Typography>
                                                    </Box>
                                                    <Divider sx={{ mr: 'var(--margin-divider)', ml: 'var(--margin-divider)' }} orientation="vertical" flexItem />
                                                    <Box>
                                                        <Typography variant="body2" color='#607D8B'>Uploaded: {new Date(selectedVideo.created_at).toLocaleDateString(undefined, {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: 'numeric',
                                                            hour12: true,
                                                        })}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Item sx={{ my: '1rem', boxShadow: 'none', backgroundColor: '#F9F9F9' }}>
                                                <Typography variant="body2" color='text.primary'>Description :-</Typography>
                                                <Typography variant="body2" color='var(--color-black)'> {/* color:'text.secondary' */}
                                                    {selectedVideo.description}
                                                </Typography>
                                            </Item>
                                            <Stack direction="row" spacing={1}>
                                                <Chip label={selectedVideo?.tags?.length > 0 ? selectedVideo?.tags[0].name : 'No tags added'} />
                                            </Stack>
                                        </Stack>
                                    </Item>
                                </Stack>


                            </Box>
                            {/* <Box sx={{ backgroundColor: '#F8FBFF', minHeight: "16rem" }}></Box> */}
                        </Box>
                    </Grid>
                    {/* // ))} */}

                    {/* //RIGHT PART */}
                    <Grid item xs={12} md={5} lg={4} sx={{ minHeight: '100vh', height: 'inherit' }}>
                        <Stack sx={{ height: '100%' }}>
                            <Accordion expanded={true} >
                                <AccordionSummary
                                    sx={{ p: 'var(--padding-right-only)', backgroundColor: "var(--color-lightestblue)", margin: '0 !important' }} //removed minHeight: '0px!important',
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header">
                                    <Box sx={{ display: 'flex', ml: 2 }}>
                                        <Box><InfoOutlinedIcon /></Box>
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant="subtitle1" component="h2">
                                                {pageName}
                                            </Typography>
                                            {/* <Typography variant="subtitle1">
                                            {pageName}
                                            </Typography> */}
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "var(--color-white)", p: 1, maxHeight: '25rem', overflow: 'auto' }}>
                                    {videoListByCategory?.map((video) => (
                                        // <Box className="container">
                                        //     <Box className="card-container" id="left">
                                        <Card key={video.id} sx={{ p: 1, mt: 1, backgroundColor: isVideoSelected(video) ? 'lightblue' : 'inherit' }}>
                                            <Grid container spacing={2} sx={{ p: 1 }} onClick={() => handleCardClick(video)}>
                                                <Grid item xs={4} sm={4} sx={{ display: "flex" }}>
                                                    <Box><DragIndicatorOutlinedIcon /></Box>
                                                    <Box style={{ position: 'relative', ml: 1 }}>
                                                        <img src={video.thumbNailUrl} height="78px" width="78px" />
                                                        <Fab
                                                            className="play-icon"
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                opacity: 0,
                                                                transition: 'opacity 0.3s ease',
                                                            }}
                                                            color="primary"
                                                            aria-label="play arrow"
                                                        >
                                                            <PlayArrowIcon />
                                                        </Fab>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box>
                                                        <Typography variant="subtitle1">
                                                            {video?.name?.length > 15 ? video?.name.substring(0, 15) + '...' : video?.name}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {new Date(video?.created_at).toLocaleDateString(undefined, {
                                                                month: 'long',
                                                                day: 'numeric',
                                                                year: 'numeric',
                                                                hour12: true,
                                                            })}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={(event) => {
                                                    selectionCheckboxClear(video)
                                                }}>
                                                    <MenuBar data={methodsAndState.checkedData} value={true} />
                                                </Grid>
                                            </Grid>
                                        </Card>
                                        //     </Box>
                                        // </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Box sx={{ mt: 2 }}>
                                <img src={Advertisment} alt="Advertisment" className="responsive-img" style={{ borderRadius: '6px' }}></img>
                            </Box>
                            {/* </Item> */}
                        </Stack>

                    </Grid>
                </Grid>
                <DynamicSnackbar />
                <DownloadBar />
            </Box>
        </MyContext.Provider>
    )
}

export default ViewVideos