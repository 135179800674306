import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { render } from 'react-dom';
import AppWrapper from './AppWrapper';
import store from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <React.StrictMode >
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  // </React.StrictMode>
);