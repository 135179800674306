import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { Observable } from '../../form/helper/observableHelper.js';

const checkboxHelper = () => {

    let self = {};

    self.selectedItems = new Set([]);
    self.headerCheckStatus = new Observable(false);
    self.headerIntermidiateStatus = new Observable(false);
    self.selectedItemsObservable = new Observable([]);
    self.intermidiateStatus = new Observable(false);
    self.numElements = Infinity;
    self.elementList = [];

    self.setNumElements = (elements) => {
        self.numElements = elements.filter(v => !v.disable).length;
        self.elementList = elements.filter(v => !v.disable).map(v => v.id);
    }

    self.handleCheckbox = function (id) {
        self.selectedItems.has(id);
    };

    self.checkboxListener = function (id) {
        if (self.selectedItems.has(id)) {
            self.selectedItems.delete(id);
        } else {
            self.selectedItems.add(id);
        }
        self.selectedItemsObservable.set(Array.from(self.selectedItems));

        self.headerIntermidiateStatus.set(false);
        if (self.selectedItems.size == self.numElements) {
            self.headerCheckStatus.set(true);
        } else if (!self.selectedItems.size) {
            self.headerCheckStatus.set(false);
        } else {
            if (self.intermidiateStatus.get())
                self.headerCheckStatus.set(true);
            self.headerIntermidiateStatus.set(true);
        }
    };

    self.headerCheckboxListener = function (e, c) {
        self.headerIntermidiateStatus.set(false);
        self.headerCheckStatus.set(!self.headerCheckStatus.get());
        if (self.headerCheckStatus.get()) {
            self.elementList.filter(v => v != "file_undefined").map(v => self.selectedItems.add(v));
        } else {
            self.selectedItems.clear();
        }
        self.selectedItemsObservable.set(Array.from(self.selectedItems));
    };
    
    self.clearSelection = function () {
        if(Array.from(self.selectedItems).length){
            self.selectedItems.clear();
            self.selectedItemsObservable.set(Array.from(self.selectedItems));
        }
    }

    self.init = (elementList, setSelectedItems, setHeaderCheckStatus, setShowCode, setHeaderIntermidiateStatus) => {
        self.setNumElements(elementList);
        self.selectedItemsObservable.subscribe((val) => {
            setSelectedItems(new Set(Array.from(self.selectedItems)));
            if(setShowCode)
                setShowCode(self.selectedItems.size > 0)
        })
        self.headerCheckStatus.subscribe((value) => {
            setHeaderCheckStatus(value ? true : false);
        })
        if (setHeaderIntermidiateStatus) {
            self.intermidiateStatus.set(true);
            self.headerIntermidiateStatus.subscribe((value) => {
                setHeaderIntermidiateStatus(value ? true : false);
            })
        }
    }

    self.setIntermidiateStatus = (status) => {
        self.intermidiateStatus.set(status);
        if(!status){
            self.headerCheckStatus.set(false);
            self.headerIntermidiateStatus.set(false);
        } else {
            console.log({status: self.selectedItems.size != self.numElements && self.selectedItems.size})
            self.headerCheckStatus.set(self.selectedItems.size);
            self.headerIntermidiateStatus.set(self.selectedItems.size != self.numElements && self.selectedItems.size);
        }
    }

    // ------------------ snackbar message handler ----------------

    self.snackBarMessageHandler = function (file, folder) {
        let fileText = " ";
        let folderText = " ";

        if (file.length && file.length > 1) {
            fileText = " files ";
        } else if (file.length === 1) {
            fileText = " file ";
        }

        if (folder.length && folder.length > 1) {
            folderText = " folders ";
        } else if (folder.length === 1) {
            folderText = " folder ";
        }
        return { fileText, folderText };
    }

    return self;
}

export default checkboxHelper;