import React, { useContext } from 'react';
import PlaylistGridView from './PlaylistGridView';
import AllVideoGrid from './AllGridView';
import AllVideoTreeView from './AllVideoTreeView';
import MyContext from './Mycontext';
import { Box } from '@mui/material';

const VideoLibraryGridandTree = ({isTrash = false, isPinned = false, checkboxRequied = true, page}) => {
    const methodsAndState = useContext(MyContext);
    return (
        <Box>
            {methodsAndState.showGridView && <AllVideoGrid page={page} isPinned={isPinned} isTrash={isTrash} checkboxRequied={checkboxRequied} />}
            {methodsAndState.showTreeView && <AllVideoTreeView page={page} isPinned={isPinned} isTrash={isTrash} checkboxRequied={checkboxRequied} />}
        </Box>
    );
};

export default VideoLibraryGridandTree;