import React from 'react'
import { Box, TextField,Button,Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer, closeDrawer } from '../../../../../../features/drawers/drawerSlice';

const Advance = () => {

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
      dispatch(closeDrawer());
  };

  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Song Meta"
        required
        multiline
        rows={14}
        defaultValue="Song Meta"
        sx={{ width: '100%' }}
      />

      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '2rem', height: '4rem' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
          <Button variant="contained" size="small"
            onClick={handleDrawerClose}>SUBMIT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default Advance