import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Stack, Card, Fab, CardContent, CardMedia, CardActionArea, Grid,TextField, Chip, InputLabel, MenuItem, Avatar, Link, FormControl, Select, Typography, Autocomplete, Button, Divider, Snackbar, Alert } from '@mui/material';
import ApiService from '../../../../../../utils/ApiService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { v4 as uuidv4 } from 'uuid';
import { getAppToken, getSelfToken } from '../../../../../../_helper/secureToken';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer, closeDrawer } from '../../../../../../features/drawers/drawerSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import '../../../../../../layouts/footer/Footer.css'
import CreatePlaylistDialog from '../../../../../../pages/KnowledgeManagement/DialogKM/CreatePlaylistDialog';
import SharedDialog from '../../../../../../features/dialogBox/dialogBox';
import { openSnackbar, closeSnackbar } from '../../../../../../features/snackbar/snackbarSlice';
import { openDialog, closeDialog } from "../../../../../../features/dialogBox/dialogBoxSlice";
// import MyContext from '../../../../../../pages/KnowledgeManagement/Mycontext';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Details = ({context}) => {
  const methodsAndState = useContext(context);
  const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
  console.log(selectedData)
  // const chunkSize = 1048576 * 1;//its 1MB, increase the number measure in mb
  const chunkSize = 1048576 * 0.5;
  const [showProgress, setShowProgress] = useState(false)
  const [counter, setCounter] = useState(1)
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const [fileSize, setFileSize] = useState(0)
  const [categoriesName, setCategoriesName] = useState([]);
  // const [currentPlaylist, setCurrentPlaylist] = useState(0);
  const [fileName, setFileName] = useState("")
  const [validCategory, setValidCategory] = useState([])
  const [chunkCount, setChunkCount] = useState(0)
  const [videoStream, setVideoStream] = useState("")
  const [video, setVideo] = useState("")
  const [videoDetail, setVideoDetail] = useState('')
  const userId = useSelector((state) => state?.user?.userListName.userId)
  console.log('abc', userId)
  const userDetail = useSelector((state) => state?.user?.userListName)
  console.log('abc', userDetail)
  // const userList = useSelector((state) => state?.user?.userListName);


  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
  }

  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked)
  };
  // const progressInstance = <ProgressBar animated now={progress} label={`${progress.toFixed(3)}%`} />;
  const progressInstance = <Box>{progress}</Box>;
  const [playlistData, setPlaylistData] = React.useState([]);
  const [playlist, setPlaylist] = React.useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [draftVideoID, setDraftVideoID] = useState('');
  const [url, setURL] = useState('');
  const [uploadVideoDetails, setUploadVideoDetails] = useState({});
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const handleChangePlaylist = (event, values) => {
    setPlaylist(values);
  };

  const [categoryData, setCategoryData] = React.useState([]);
  const [Category, setCategory] = React.useState('');

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const [subcategoryData, setSubcategoryData] = React.useState([]);
  const [Subcategory, setSubcategory] = React.useState('');

  const handleChangeSubcategory = (event) => {
    setSubcategory(event.target.value);
  };

  const [tags, setTags] = useState([]);
  console.log('tag', tags)
  const [selectedTags, setSelectedTags] = useState([]);
  console.log(selectedTags)
  // const [streamTagsName, setStreamTagsName] = React.useState('');

  // const [titleValue, setTitleValue] = useState('');

  // const handleTitleValue = (event) => {
  //   setTitleValue(event.target.value);
  //   console.log(event.target.value)
  // };
  const [titleValue, setTitleValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleTitleValue = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 100) {
      setTitleValue(newValue);
      setErrorMessage('');
    } else {
      setErrorMessage('Error: Title should not exceed 100 characters.');
    }
  };
  const [descriptionValue, setDescriptionValue] = useState('');

  const handleDescriptionValue = (event) => {
    setDescriptionValue(event.target.value);
    console.log(event.target.value)
  };

  // const [listValues, setListValues] = useState([]);
  // const [options, setOptions] = useState([
  //   { id: 1, title: 'Option 1' },
  //   { id: 2, title: 'Option 2' },
  //   { id: 3, title: 'Option 3' },
  //   // ... add more options as needed
  // ]);

  // const handleChange = (event, values) => {
  //   setListValues(values);
  // };

  // const handleAddNewCategory = () => {
  //   // Handle the logic for adding a new category
  //   const newCategory = { id: options.length + 1, title: `Option ${options.length + 1}` };
  //   setOptions((prevOptions) => [...prevOptions, newCategory]);
  // };

  // const methodsAndState = {
  //   openDialogPublish,
  // }

  const CustomListbox = ({ children, ...other }) => {
    return (
      <List {...other} sx={{ height: '210px !important', overflow: 'hidden !important' }}>
        <Box sx={{ height: '150px', overflow: 'auto' }}>  {children}</Box>
        <Divider />
        <footer >
          <ListItem sx={{ height: '50px !important' }}>
            <Button variant="text" onClick={() => { openDialogPublish('createPlaylistDialog') }}>
              <AddIcon />
              NEW PLAYLIST
            </Button>
          </ListItem>
        </footer>
      </List>
    );
  };

  const [Practice, setPractice] = React.useState('');

  const handleChangePractice = (event) => {
    setPractice(event.target.value);
  };
  const [Industry, setIndustry] = React.useState('');

  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const [Technology, setTechnology] = React.useState('');

  const handleChangeTechnology = (event) => {
    setTechnology(event.target.value);
  };
  const [Location, setLocation] = React.useState('');

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };
  const [Stage, setStage] = React.useState('');

  const handleChangeStage = (event) => {
    setStage(event.target.value);
  };

  // const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageThumb, setImageThumb] = useState(null);
  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   console.log(selectedFile)
  //   setFile(selectedFile);
  // };
  const handleFileChange = (e) => {
    // const fileThumbnail = e.target.files[0];
    const file = e.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      setImageThumb(file);
      setSelectedFile(url);
    }
    // You may want to perform additional validations here
    // if (fileThumbnail) {
    //   try {
    //     const objectURL = URL.createObjectURL(fileThumbnail);
    //     // Directly use the file or objectURL as needed
    //     setSelectedFile(objectURL);
    //     console.log(objectURL);
    //   } catch (error) {
    //     console.error('Error creating object URL:', error);
    //   }
    // }

    // Update the state if needed
    // setSelectedFile(file);
    console.log(selectedFile)
    // console.log(fileThumbnail)
  };

  // const handleRemoveImage = () => {
  //   // setFile();
  //   setSelectedFile(null);
  // };


  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
  }, [fileToBeUpload, progress])

  useEffect(() => {
    console.log({ videoStream })
    if (videoStream != "") {
      setTimeout(() => {
        getFileContext();
      }, 100)
    }
  }, [videoStream])

  const updatePlaylistData = async () => {
    try {
      const response = await ApiService.get('allPlaylistName');
      setPlaylistData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // ApiService.get('allPlaylistName').then(playlistData => {
    //   setPlaylistData(playlistData.data.data)
    //       //  console.log(department.data.data)
    //     })
  }
  useEffect(() => {
    // Fetch the initial playlist data when the component mounts
    updatePlaylistData();
  }, []);

  useEffect(() => {

    // updatePlaylistData();

    console.log("Are you sure??",{selectedItems: methodsAndState.selectedItems,dashboardList: methodsAndState.dashboardList, editFlag: methodsAndState.editFlag })
    if (methodsAndState.selectedItems.size == 1 && methodsAndState.editFlag?.value != "create") {
      const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
      console.log("a1", selectedData)
      const params = {
        related: ['streamCategory','streamSubcategory']
      }
      let paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
      console.log(paramsid)
      ApiService.get('particularPlaylist', params, paramsid).then(res => {
        if (res.data.data) {
          let data = res.data.data;
          console.log(data?.StreamSubCategory?.streamCategory?.name)
          setDescriptionValue(data.description != null ? data.description : "");
          setTitleValue(data.name);
          setSelectedTags(data.tags)
          setPlaylist(data.playlist)
          setCategory(data?.streamCategory?.id)
          setSubcategory(data?.StreamSubCategoryId)
          setChecked(data.recommendedVideo === "1")
          console.log(data.recommendedVideo)
          setSelectedFile(data?.thumbNailUrl || null)
          setVideo(data.videoUrl)
          setCategoriesName(data.subCategory)
          console.log(data.videoUrl)
          setSelectedValues(data.subCategory)
          setDraftVideoID(paramsid);
          // setSelectedTags(data.tags.map((temp) => {
          //   return temp.name;
          // }));
        }
      })
    }

    ApiService.get('allCategoriesName').then(categoryData => {
      setCategoryData(categoryData.data.data)
      //  console.log(department.data.data)
    })

    ApiService.get('allSubcategoriesName').then(subcategoryData => {
      setSubcategoryData(subcategoryData.data.data)
      //  console.log(department.data.data)
    })


    ApiService.get('streamTags').then(tags => {
      setTags(tags.data.data)
      //  console.log(department.data.data)
    })

    // ApiService.get('categoryToSubcategory').then(categories => {
    //   setCategories(categories.data.data);
    //       //  console.log(department.data.data)
    //     })
    const fetchData = async () => {
      try {
        const response = await ApiService.get('categoryToSubcategory');
        setCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return (() => {
      dispatch(closeDialog('createPlaylistDialog'));
    })
  }, [])

  const handleSelectChange = (category, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: value,
    }));
  };

  // const handleAutocompleteChange = (values, category) => {
  //   const updatedValues = [...selectedValues];
  //   const existingValue = updatedValues.find((item) => item.category === category);

  //   if (existingValue) {
  //     existingValue.subcategoryId = values.map((value) => value.id);
  //   } else {
  //     updatedValues.push({
  //       category,
  //       subcategoryId: values.map((value) => value.id),
  //     });
  //   }

  //   setSelectedValues(updatedValues);
  // };

  const handleAutocompleteChange = (values, category) => {
    const updatedValues = [...selectedValues];
    console.log(updatedValues)
    const existingValue = updatedValues.find((item) => item.category === category);
    console.log(existingValue)
    if (existingValue) {
      existingValue.subcategoryId = values.map((value) => value.id);
    } else {
      updatedValues.push({
        category,
        subcategoryId: values.map((value) => value.id),
      });
    }

    setSelectedValues(updatedValues);
  };


  const onChangeSelectCategory = (e, value, reason, data, i) => {
    // If the reason for the change is 'removeOption', handle the removal.
    if (reason === "removeOption" && value) {
      let newCategoryName = categoriesName
      const prevValue = newCategoryName.filter(v => v.category[0].id == data.id)
      const currValue = value.map(v => v.id);
      const lostValue = prevValue.filter(v => currValue.indexOf(v.id) == -1)[0].id;
      // Filter out the selected value from categoriesName.
      const index = newCategoryName.reduce((prev, curr, index) => {
        if (curr.id == lostValue)
          prev = index;
        return prev;
      }, -1);
      // Remove the selected value from the categoriesName array.
      if (index != -1)
        newCategoryName.splice(index, 1);
      setCategoriesName([...newCategoryName]); // Creating a new array reference to trigger state update
    } else if (reason === "clear") {
      let newCategoryName = categoriesName
      const currValue = newCategoryName.filter(v => v.category[0].id != data.id)
      setCategoriesName([...currValue]);
    } else {
      // Handle the addition of new values (similar to your existing code).
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (value.length) {
        const uniqueValues = new Set([...categoriesName, ...value]);
        setCategoriesName(Array.from(uniqueValues));
      }
    }
    let validation = [...validCategory, { index: i, value: value }]
    const uniqueValuesMap = new Map();
    const updatedData = [];

    validation.forEach(item => {
      const index = item.index;
      if (!uniqueValuesMap.has(index)) {
        uniqueValuesMap.set(index, updatedData.length);
        updatedData.push(item);
      } else {
        const existingIndex = uniqueValuesMap.get(index);
        const existingValues = updatedData[existingIndex].value.map(val => val.id);

        item.value.forEach(val => {
          if (!existingValues.includes(val.id)) {
            updatedData[existingIndex].value.push(val);
            existingValues.push(val.id);
          }
        });
      }
    });

    // Update the validCategory array with the filtered data
    setValidCategory(updatedData);

  }

  const isOptionEqualToValue = (value, option) => {
    return value.name === option.name;
  };


  // const handleTagChange = (event, value) => {
  //   console.log({value})
  //   const filteredValues = value.filter(v => {
  //     if (typeof v === 'string') {
  //       // If it's a string, trim and check if it's not empty
  //       return v.trim() !== "";
  //     } else {
  //       // If it's an object, you can handle it accordingly
  //       // For example, check if it has a 'name' property and if 'name' is not an empty string
  //       return typeof v.name === 'string' && v.name.trim() !== "";
  //     }
  //   });
  //   setSelectedTags(filteredValues);
  //   console.log({filteredValues})
  // };

  const handleTagChange = (event, value) => {
    // Filter out empty values
    const filteredValues = value.filter((v) => (typeof v === 'string' ? v.trim() !== '' : v.name.trim() !== ''));
    setSelectedTags(filteredValues);
  };

  const getFileContext = (e) => {
    resetChunkProperties();
    // const _file = e.target.files[0];
    const _file = videoStream
    setFileSize(_file.size)
    console.debug(fileSize, "chunk")

    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount)


    setFileToBeUpload(_file)
    const _fileID = uuidv4() + "." + _file.name.split('.').pop();
    console.debug("getFileCOntext Start")
    console.debug(_file.size, "file size")
    console.debug(_file.name, "file name")
    console.debug(_totalCount, "file total chunk count ")
    console.debug(_fileID, "file id")
    console.debug("getFileCOntext End")
    setFileGuid(_fileID)
    setFileName(_file.name)
  }

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      // var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      const chunkData = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      const chunkBlob = new Blob([chunkData], { type: fileToBeUpload.type }); // Create Blob with correct type
      const chunk = new File([chunkBlob], fileToBeUpload.name, { type: fileToBeUpload.type }); // Create File from Blob
      if (counter == chunkCount)
        uploadChunk(chunk, true)
      else
        uploadChunk(chunk)
    }
  }
  const [loading, setLoading] = useState('');
  const uploadChunk = async (chunk, is_last = null) => {
    var formData = new FormData();
    console.log({ chunk, size: (0 + chunk.size) })
    if (is_last) {
      formData.append('is_last', true);
      // formData.append('thumbNail', true);
      formData.append('file', chunk);
      formData.append('uploadedById', userId);
      formData.append('fileName', fileName);
      formData.append('name', fileName.split(".")[0]);
    } else {
      formData.append('fileName', fileName);
      formData.append('name', fileName.split(".")[0]);
      formData.append('file', chunk);
      formData.append('chunk', true);
    }
    // try {
    // const response = await axios.post("http://localhost:8000/api/upload/chunk", chunk, {
    // const response = await axios.post("https://streamapi.eela.tech/api/videos", formData, {
    //   params: {
    //     app: getAppToken()
    //     //   id: counter,
    //     //   fileName: fileGuid,
    //   },
    //   headers: {
    //     Authorization: 'Bearer ' + getSelfToken(),
    //     // 'Content-Type': 'application/json',
    //     'content-type': 'multipart/form-data',
    //   }
    // });
    setLoading(true);
    ApiService.postAdopter('folderpermission', formData).then(response => {

      const data = response.data;
      setVideoDetail(response.data.video)
      setURL(response.data.url)
      console.log({ response })
      console.debug(data, "response data")
      console.debug(chunkCount, "response chunkCount")
      console.debug(counter, "response counter")
      if (data.isSuccess) {
        setLoading(false);
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        // props.setVideoURL(data.url.url)
        if (counter == chunkCount) {
          console.debug('Process is complete, counter', counter)
          // await uploadCompleted();
          setProgress(100);
          // props.setVideoURL(data.url.url)
        } else {
          console.debug('Process, counter')
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
        if (is_last) {
          setDraftVideoID(data.video.id);
        }
        // setLoading(false);
      } else {
        console.debug("error else", data);
        console.debug('Error Occurred:', data.errorMessage)
        // setLoading(false);
      }
    }).catch((error) => {
      // methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
      // setFlag(false)
      // setLoading(false);
    }).finally(() => {
      setLoading(false);
    });


    // } catch (error) {
    //   // debugger
    //   console.debug('error', error)

    //   if (error.response) {
    //     /*
    //     * The request was made and the server responded with a
    //     * status code that falls out of the range of 2xx
    //     */
    //     console.debug(error.response.data);
    //     console.debug(error.response.status);
    //     console.debug(error.response.headers);
    //   } else if (error.request) {
    //     /*
    //     * The request was made but no response was received, `error.request`
    //     * is an instance of XMLHttpRequest in the browser and an instance
    //     * of http.ClientRequest in Node.js
    //     */
    //     console.debug(error.request);
    //   } else {
    //     // Something happened in setting up the request and triggered an Error
    //     console.debug('Error', error.message);
    //   }
    //   console.debug(error.config);
    // } finally {
    // setLoading(false); // Set loading to false regardless of success or failure
    // }
  }

  const resetChunkProperties = () => {
    setShowProgress(true)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  // _________________________ONCHANGE INPUT HANDLER______________________
  // function onChangeTextHandler(e) {
  //   if (e.target.name === "video") {
  //     setVideoStream(e.target.files[0]);
  //   }
  // }
  // const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onChangeTextHandler = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['video/mp4', 'video/avi', 'video/quicktime', 'video/x-matroska', 'video/x-ms-wmv','audio/mpeg', 'audio/wav', 'audio/mp3', 'audio/x-m4a'];

    if (file && allowedTypes.includes(file.type)) {
      // setFileToBeUpload(file);
      setVideoStream(e.target.files[0]);
    } else {
      setAlertMessage('Please select a valid video file.');
      e.target.value = '';
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    // Validate files and call selectFiles function
    setVideoStream(files);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleUploadVideoDetails = async () => {
    const formData = new FormData();
    // formData.append('fileName', fileName.split(".")[0]);
    if (titleValue) {
      formData.append('name', titleValue);
    }
    if (descriptionValue) {
      formData.append('description', descriptionValue);
    }
    // formData.append('uploadedById', userId);
    formData.append('modifiedBy', userId);
    if (Category) {
      formData.append('streamCategoryId', Category);
    }
    formData.append('recommendedVideo', isChecked ? 1 : 0);
    formData.append('draft', 0);
    // formData.append('thumbNailImage', imageThumb);
    // if (imageThumb) {
    //   formData.append('thumbNailImage', imageThumb, imageThumb.name);
    // }
    if (imageThumb && (imageThumb !== selectedFile || !selectedFile)) {
      formData.append('thumbNailImage', imageThumb, imageThumb.name);
    }
    setSelectedFile(null);
    setImageThumb(null);
    if (Subcategory) {
      formData.append('streamSubcategoryId', Subcategory);
    }
    playlist.forEach((playlistItem, index) => {
      formData.append(`playlistId[${index}]`, playlistItem.id);
    });
    // thumbNailImage
    selectedTags.forEach((tag, index) => {
      formData.append(`tag[${index}]`, tag.name ?? tag);
    });
    // formData.append('playlistId', playlist);
    // formData.append('subCategoryId', Subcategory);
    // Object.entries(selectedValues).forEach((subcategoryId, index) => {
    //   // formData.append(`${categoryName}_subCategoryId`, subcategoryId);
    //   formData.append(`subCategoryId[${index}]`, subcategoryId[1]);
    //   console.log(subcategoryId)
    // });

    // Object.entries(selectedValues).forEach((subcategoryId , index) => {
    //   formData.append(`subCategoryId[${index}]`, subcategoryId[1]);
    //   console.log(subcategoryId)
    // });

    // selectedValues.forEach((selectedValue, index) => {
    //   formData.append(`subCategoryId[${index}]`, selectedValue.subcategoryId);
    //   console.log(selectedValue);
    // });
    let i = 0;
    selectedValues.forEach(({ subcategoryId }, index) => {
      subcategoryId?.forEach((id, subIndex) => {
        console.log({ name: `subCategoryId[${i}]`, id })
        formData.append(`subCategoryId[${i}]`, id);
        i++;
      });
    });

    // const uniqueArray = Array.from(new Set(categoriesName.map(JSON.stringify))).map(JSON.parse);

    //     if (uniqueArray.length) {
    //       uniqueArray.forEach(d => {
    //         formData.append('subCategoryId[]', d.id)
    //       })
    //     }


    // return;
    // Append selected files to the FormData
    // fileUploadData.forEach((file, index) => {
    // //   formData.append(`file${index + 1}`, file);
    // formData.append(`file`, file);
    // });
    try {
      const response = await ApiService.postAdopter('particularPlaylistUpdate', formData, draftVideoID);
      setUploadVideoDetails(response);

      // let videoData = {
      //   StreamSubCategoryId: null,
      //   created_at: "2024-02-26T07:10:45.000000Z",
      //   deleteBy: null,
      //   description: descriptionValue,
      //   draft: null,
      //   id: draftVideoID,
      //   metadata: null,
      //   modifyBy: null,
      //   name: titleValue,
      //   pinned: false,
      //   playlist: [],
      //   recommendedVideo: null,
      //   size: "524288",
      //   streamCategoryId: null,
      //   subCategory: [
      //     // {
      //     //   created_at: "2024-01-18T17:43:24.000000Z"
      //     //   deleted_at: null
      //     //   description: "Data Management"
      //     //   id: 19
      //     //   name: "Data Management",
      //     //   updated_at: "2024-01-18T17:43:24.000000Z"
      //     // }
      //   ],
      //   tags: [],
      //   thumbNailUrl: "https://gatewayapi.eela.tech/media/",
      //   updated_at: "2024-02-26T07:10:45.000000Z",
      //   uploadedBy: {
      //     LastName: userDetail?.lastName,
      //     avatar: null,
      //     created_at: null,
      //     departmentId: null,
      //     designationId: null,
      //     email: userDetail?.email,
      //     firstName: userDetail?.userName,
      //     id: userDetail?.id,
      //     middleName: userDetail?.middleName,
      //     updated_at: null,
      //   },
      //   uploadedById: "314",
      //   url: "//333_1708931445.mp4",
      //   video: "333_1708931445.mp4",
      //   videoUrl: "https://streamapi.eela.tech/api/stream?file=333_1708931445.mp4",
      // }

      ApiService.get('particularPlaylist', {}, draftVideoID).then( res => {
        let data = res.data.data;
        // if(data){
        //   {methodsAndState.addNewVideo(data, isChecked);
        //   dispatch(closeDrawer());
        //   dispatch(openSnackbar({ dataLoad: true, message: 'Your video has been added successfully', severity: "info" }));
        //   // console.log('You have successfully applied to a job',{response})
        //   }
        // }
        if (data) {
          if (methodsAndState.editFlag?.value === "create") {
              methodsAndState.addNewVideo(data, isChecked);
              dispatch(openSnackbar({ dataLoad: true, message: 'Your video has been added successfully', severity: "info" }));
            } else {
              let updateData = {...data}
                updateData['modifyBy'] = {id: userDetail?.userId, firstName: userDetail?.userName, lastName: userDetail?.lastName};
              methodsAndState.editNewVideo(updateData, isChecked);
              dispatch(openSnackbar({ dataLoad: true, message: 'Your video has been Edited successfully', severity: "info" }));
            }
            dispatch(closeDrawer());
      }
      }).catch((e) => {

      })
      // onApplicantCount();
      // window.location.reload(true);

    } catch (error) {
      // Handle API call error
      console.log(error.message, error)
      dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      dispatch(closeDrawer());
      // alert(error.message)
    }
    // fetchData()
    // setAnchorEl(null);
  };

  // const updatePlaylistCount = (newPlaylist) => {
  //   let allPlaylist = [...playlist];
  //   allPlaylist[currentPlaylist].name = allPlaylist[currentPlaylist].name + 1;
  //   setPlaylist(allPlaylist);
  //   setPlaylistData((prevData) => [...prevData, newPlaylist]);
  // };

  // const updatePlaylistData = (newPlaylist) => {
  //   // Update the playlist data in YourComponent
  //   setPlaylistData((prevData) => [...prevData, newPlaylist]);
  // };

  const filteredValidData = validCategory.map(item => {
    item.value = item.value.filter(subItem => {
      return categoriesName.some(categoryItem => categoryItem.name === subItem.name);
    });
    return item;
  });

  // Check if every filteredValidData has a 'value' property with a length greater than 0
  const hasValueLength = filteredValidData.every(item => item.value.length > 0);


  const disabledFields = methodsAndState.editFlag?.flag || methodsAndState.editFlag?.value == "create";

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    // <MyContext.Provider value={methodsAndState}>
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>

            {methodsAndState.editFlag?.value == "create" &&
              <>
                {!url ?
                  <>
                    <label htmlFor="video">
                      <Stack
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // marginTop: "0.5rem",
                          border: "2px dashed rgba(0, 0, 0, 0.12)",
                          borderRadius: "12px",
                          height: '220px',
                          // width:'100% !important',
                          backgroundColor: "rgba(201, 201, 201, 0.1)",
                          ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                        }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                          <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                            <UploadFileIcon color="primary" />
                          </Avatar>
                        </Box>
                        <Box className="upload-btn-wrapper">
                          <span>
                            <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                              Click to upload
                            </Link>
                            <input type="file" className="form-control box-file" id="video"
                              label="Example file input" name="video" onChange={(e) => onChangeTextHandler(e)} accept="video/mp4, video/avi, video/quicktime, video/x-matroska, video/x-ms-wmv, audio/mpeg, audio/wav, audio/mp3, audio/x-m4a" />
                          </span>
                          <span> or drag and drop *</span>
                          {alertMessage &&
                      <Box>
                        <p class="upload-btn-wrapper">
                        <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {alertMessage}
                      </Alert> 
                        </p>
                      </Box>

                    }
                          <Box style={{ display: loading ? 'block' : 'none', marginTop: '1rem' }}>
                            <CircularProgress />
                          </Box>
                        </Box>
                        {/* <Box m='1rem 0rem 0rem'>
                      <Typography variant="caption" color="text.secondary" display="block" gutterBottom> PNG, JPG, XLS, DOC, PDF and PPT (max. 25mb)</Typography>
                    </Box> */}
                        {/* <Box mb='1rem'>
                      {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {extensionErr}
                      </Alert> : ""}
                    </Box> */}
                      </Stack>
                    </label>
                    {/* {videoStream && <Button onClick={getFileContext} className="uploadVideoButton" variant="contained" >
                    Upload
            </Button>} */}
                    

                    <Box style={{ display: progress > 0 && progress < 100 ? 'block' : 'none', marginTop: '1rem' }}>
                      <LinearProgress value={progress} variant="determinate" sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                    </Box>
                  </>
                  :
                  <Card >
                    <CardActionArea>
                      <CardMedia
                        sx={{
                          borderRadius: '1rem',
                          padding: '1px',
                          position: 'relative',
                          overflow: 'hidden',
                          '&:hover .play-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box style={{
                          position: 'relative',
                          // padding: '1rem'
                        }}>
                          <video
                            style={{
                              width: '100%',
                              height: '220px'
                            }}
                            src={url}
                            alt="video alt text"
                            controls
                          />

                        </Box>
                      </CardMedia>
                    </CardActionArea>
                    <Box sx={{ p: 1, display: 'flex' }}>
                      <Box>
                        <Typography variant='body2'>Video Link: <Link>{url}</Link></Typography>
                        <Box sx={{ display: 'flex' }}><Box><Typography variant='body2'>Uploaded: {formatDate(videoDetail.updated_at)}</Typography></Box> <Box sx={{ mx: 0.5 }}>|</Box> <Box><Typography variant='body2'>By {userDetail.userName + ' ' + userDetail.lastName}</Typography></Box></Box>
                        {/* <Typography variant='body2'>Uploaded: {formatDate(videoDetail.updated_at)} | By {videoDetail.name}</Typography> */}
                      </Box>
                      {/* <Box sx={{alignItems:'center',display:'flex'}}> */}

                      {loading ?
                        <Box>
                          <CircularProgress />
                        </Box>
                        :
                        <Box sx={{ ml: 1, display: 'flex' }}>
                          <Box onClick={handleCopy} style={{ cursor: 'pointer' }}><ContentCopyIcon /></Box>
                          <label htmlFor="editFile" style={{ display: 'flex', cursor: 'pointer' }}>
                            <Box sx={{ mb: 1.5, ml: 1 }}><ModeEditIcon /></Box>
                            <input type="file" id="editFile" style={{ display: 'none' }}
                              label="Edit Video" name="video" onChange={(e) => onChangeTextHandler(e)} />
                          </label>
                        </Box>
                      }
                      {/* </Box> */}
                    </Box>
                  </Card>
                }
              </>
            }

            {methodsAndState.selectedItems?.size == 1 && methodsAndState.editFlag?.value != "create" &&
              <Card >
                <CardActionArea>
                  <CardMedia
                    sx={{
                      borderRadius: '1rem',
                      padding: '1px',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover .play-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Box style={{
                      position: 'relative',
                      // padding: '1rem'
                    }}>
                      <video
                        style={{
                          width: '100%',
                          // height: '220px'
                        }}
                        src={video}
                        alt="video alt text"
                        controls
                      />
                      {/* {key.numVideos != undefined && (
                          <Box sx={{background: '#000', width: 'calc( 100% - 2rem )', color: '#fff', position: 'absolute', bottom: '0px', display: 'flex', pl: 1 }}>
                            <PlaylistPlayIcon /> 
                           </Box>
                         )}  */}
                      {/* <Fab
      className="play-icon"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
      }}
      color="primary"
      aria-label="play arrow"
      >
      <PlayArrowIcon />
    </Fab> */}
                    </Box>
                  </CardMedia>
                </CardActionArea>
              </Card>
            }

            <Box sx={{ my: 2 }}>
              <Typography variant="subtitle1">Add Thumbnail</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <form> */}
                {/* <label htmlFor="fileInput">Choose a file:</label> */}
                <Card sx={{
                  border: '1px dashed', height: '92px', // Set height to 100%
                  // width:"122px",
                  cursor: 'pointer',
                  alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', padding: '10px'
                }}>
                  <label htmlFor="fileInput" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', }}>
                    {/* <Avatar sx={{ marginRight: '8px',p:2 }}><ImageOutlinedIcon /></Avatar>
        upload thumbnail */}
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', marginRight: '8px', backgroundColor: '#1976D21F' }}>
                      <ImageOutlinedIcon color="primary" />
                    </Avatar>
                    <Link><Typography variant="body1">upload thumbnail *</Typography></Link>
                  </label>
                </Card>
              </Grid>

              <input
                type="file"
                id="fileInput"
                required
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept=".png, .jpg, .jpeg,.gif"
              />
              {/* </form> */}

              <Grid item xs={6}>
                {selectedFile && (
                  <Card sx={{
                    textAlign: 'center',
                    height: '92px', // Set height to 100%
                    // width:"122px",
                    display: 'flex',
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'center', // Center children vertically
                    alignItems: 'center',
                  }}>
                    {/* <p>Selected File: {selectedFile.name}</p> */}
                    <img
                      // src={URL.createObjectURL(selectedFile)}
                      src={selectedFile}
                      alt="Selected"
                      // className='imgfit'
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    {/* <button onClick={handleRemoveImage}>Remove Image</button> */}
                  </Card>
                )}
              </Grid>
            </Grid>

            <FormControlLabel sx={{ pt: 1.5 }} required control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />} label="Add this as a recommended video" />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box>
                <TextField
                  required
                  id="outlined-required"
                  label="Title"
                  value={titleValue}
                  onChange={handleTitleValue}
                  // size="small"
                  sx={{ width: '100%' }}
                />
                
                 {errorMessage && 
                //  <Box>{errorMessage}</Box>
                <Alert variant="none" sx={{ color: "red" }} severity="error">
                {errorMessage}
              </Alert> 
                 }
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Description"
                  value={descriptionValue}
                  onChange={handleDescriptionValue}
                  // size="small"
                  multiline
                  rows={3}
                  sx={{ width: '100%' }}
                />
                 
              </Box>
              <Box sx={{ mt: 2 }}>
                {/* <FormControl size="small" fullWidth>
                  <InputLabel id="demo-select-small-label">playlist</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={playlist}
                    label="playlist"
                    onChange={handleChangePlaylist}
                    sx={{ width: '100%' }}
                  >
                    
                    {playlistData.map((d) => { 
                                        return (
                                    <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>
                                    )})}
                  </Select>
                </FormControl> */}
                <FormControl
                  // size="small"
                  fullWidth>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={playlistData}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    value={playlist}
                    onChange={handleChangePlaylist}
                    renderInput={(params) => (
                      <TextField {...params} label="Playlist" placeholder="Playlist" />
                    )}
                    ListboxComponent={CustomListbox}
                  />
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small-label">Category</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Category}
                    label="Category"
                    onChange={handleChangeCategory}
                    sx={{ width: '100%' }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                    {categoryData.map((d) => {
                      return (
                        <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small-label">Subcategory</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Subcategory}
                    label="Subcategory"
                    onChange={handleChangeSubcategory}
                    sx={{ width: '100%' }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                    {subcategoryData.map((d) => {
                      return (
                        <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Box>



      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1">Other Details</Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <Autocomplete
              multiple
              id="tags-outlined"
              size="small"
              options={[]}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              // onChange={onChangeOptionHandler}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Tag"
                />
              )}
            /> */}

            {/* <form>
        <label htmlFor="fileInput">Choose a file:</label>
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
        />
        <Card><img src={file} /></Card>
      </form> */}


            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={tags}
                // size="small"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)} // Assuming the tag name is stored in the 'tag' property
                // filterSelectedOptions
                freeSolo
                value={selectedTags}
                onChange={handleTagChange}
                renderTags={(filteredValues, getTagProps) =>
                  filteredValues.map((option, index) => (
                    <Chip variant="outlined" label={typeof option === 'string' ? option : option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tags" placeholder="Select tags" />
                )}
              />
            </FormControl>
          </Grid>

          {/* {categories.map((category) => (
          <Grid item xs={6} key={category.id}>
            <FormControl size="small" fullWidth>
              <Autocomplete
                id={`${category.id}-autocomplete`}
                options={category.subCategory}
                getOptionLabel={(option) => option.name}
                onChange={(e, values) => handleAutocompleteChange(values, category.name)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={category.name} />
                )}
                multiple  // This enables multiple selection
              />
            </FormControl>
          </Grid>
        ))} */}

          {/* {categories.map((category) => (
          <Grid item xs={6} key={category.id}>
            <FormControl fullWidth>
              <Autocomplete
                id={`${category.id}-autocomplete`}
                options={category.subCategory}
                getOptionLabel={(option) => option.name}
                onChange={(e, values) => handleAutocompleteChange(values, category.name)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={category.name} />
                )}
                multiple  
              />
            </FormControl>
          </Grid>
        ))} */}
          {categories.map((d, i) => (
            <Grid item xs={6} key={d.id}>
              <FormControl fullWidth>
                <Autocomplete
                  // open={true}
                  // sx={{ width: '38rem' }}

                  id={`${d.id}-autocomplete`}
                  multiple
                  limitTags={2}
                  onChange={(e, values) => handleAutocompleteChange(values, d.name)}
                  defaultValue={categoriesName.filter(
                    (item) => {
                      let subcategoriesInCategory = d.subCategory.map(v => v.id);
                      console.log({ subcategoriesInCategory, item, d })
                      if (subcategoriesInCategory.indexOf(item.id) != -1) {
                        return item.id;
                      }
                    }
                  )}
                  options={d.subCategory}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params, index) => (
                    <TextField {...params} variant="outlined" label={d.name} />
                  )}
                // disabled={disabledFields ? false : true}
                // isOptionEqualToValue={isOptionEqualToValue}
                />
              </FormControl>
            </Grid>
          ))}

          {/* {categories.map((category) => (
             <Grid item xs={6}>
        <FormControl size="small" key={category.id} fullWidth>
          <InputLabel id={`${category.id}-label`}>{category.name}</InputLabel>
          <Select
            labelId={`${category.id}-label`}
            id={`${category.id}-select`}
            value={selectedValues[category.name] || ''}
            onChange={(e) => handleSelectChange(category.name, e.target.value)}
            label={category.name}
          >
            {category?.subCategory.map((subcategory) => (
              <MenuItem key={subcategory.id} value={subcategory.id}>
                {subcategory.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
      ))} */}
          {/* <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Practice</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={Practice}
                label="Practice"
                onChange={handleChangePractice}
                sx={{ width: '100%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
          {/* </Grid> */}
          {/* <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Industry</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={Industry}
                label="Industry"
                onChange={handleChangeIndustry}
                sx={{ width: '100%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Technology</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={Technology}
                label="Technology"
                onChange={handleChangeTechnology}
                sx={{ width: '100%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Location</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={Location}
                label="Location"
                onChange={handleChangeLocation}
                sx={{ width: '100%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Stage</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={Stage}
                label="Stage"
                onChange={handleChangeStage}
                sx={{ width: '100%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={null}
            checkedIcon={null}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Checkboxes" placeholder="Favorites" />
      )}
      ListboxComponent={CustomListbox}
      onChange={handleChange}
      value={listValues}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    /> */}


        </Grid>
      </Box>
      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '2rem', height: '4rem' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
          <Button variant="contained" size="small"
            disabled={
              titleValue.length == '' ||
              descriptionValue.length == '' ||
              (!selectedFile || !fileName) && !video
              // && !is_last
              // (!imageThumb ) && !video
            }
            onClick={() => handleUploadVideoDetails()}>SUBMIT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
        </Stack>
      </Box>
      <SharedDialog id="createPlaylistDialog">
        <CreatePlaylistDialog onUpdatePlaylist={updatePlaylistData} />
      </SharedDialog>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Link copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
    // </MyContext.Provider>
  )
}

export default Details