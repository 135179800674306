import React, { useState, useEffect, useRef } from 'react'
import { Box, Card, Grid, Stack, Button } from '@mui/material'
import DashboardHeading from './DashboardHeading';
import TransitionsSnackbar from './Constantfile/Snackbar';
import { permissionDataList } from '../../features/permission/permissionData';
import ApiService from '../../utils/ApiService';
import MyContext from './Mycontext';
import DashboardTools from './DashboardTools';
import AllVideoGrid from './AllGridView';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import FileDashboardKM from './DrawerDialogKM/FileDashboardKM';
import SharedDialog from '../../features/dialogBox/dialogBox';
// import SharedDrawer from '../../features/drawers/Drawer';
import RenameDialog from './DialogKM/RenameDialog';
import DeleteDialog from './DialogKM/DeleteDialog';
// import { openDrawer } from '../../features/drawers/drawerSlice';
import { openDialog } from '../../features/dialogBox/dialogBoxSlice';
import VideoLibraryGridandTree from './VideoLibraryGridandTree';
import VideoLibraryTools from './VideoLibraryTools';
import checkboxHelper from './checkboxHelper';
import { setPageNo, setScrollFlag } from '../../features/dashboardData/dashboardData';
import { setCategoryDataList } from '../../features/commonDataSlice/categoryData';
import MoveDialog from './DialogKM/MoveDialog';
import AddIcon from '@mui/icons-material/Add';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import SharedDrawer from '../../features/drawers/Drawer';
import CreatePlaylist from '../../components/drawerBody/Tstream/FileLibrary/StreamDrawer/CreatePlaylist';
import UploadDrawer from '../../components/drawerBody/Tstream/FileLibrary/StreamDrawer/UploadDrawer';
import transformationHelper from '../../utils/transformationHelper';
import PageName from '../../utils/getPageName';
import { loaderData } from '../../features/loader/loaderDashboard';

const Playlist = () => {
  const dispatch = useDispatch();
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dashboardList, setDashboardList] = useState([]);
  const userList = useSelector((state) => state?.user?.userListName)
  const [showGridView, setShowGridView] = useState(true);
  const [showTreeView, setShowTreeView] = useState(false);
  const [tagsFlag, setTagsFlag] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set([]));
  const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
  const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
  // const [categoryList, setCategoryList] = useState([]);
  const [isFixedTop, setisFixedTop] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [metaData, setMetaData] = useState();
  const categoryListData = useSelector((state) => (state.category));

  const headerRef = useRef();
  const element = document.querySelector('#headerWidthListner');
  const [widthHeader, setWidthHeader] = useState("100%");
  // const listener = useRef(null);
  const [checkedData, setCheckedData] = useState();
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [dashboardListMaster, setDashboardListMaster] = useState([]);
  const dashboardScroll = useSelector((state) => (state.dashboardScroll));
  const [checkboxGridHelper, setCheckboxGridHelper] = useState(
    checkboxHelper()
  );
  const [breadCrumb, setBreadCrumb] = useState([{ id: 0, name: "Pinned" }]);
  const [sortNameType, setSortNameType] = useState({});
  const [sortBy, setSortBy] = useState(''); // Initialize the sorting order to 'asc'
  var sortName = { name: '', sortValue: '', sortBy: '' }
  const searchList = useSelector((state) => (state.search));
  // const formatData = transformationHelper().getTransformationFunction;

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.target.getBoundingClientRect();
        setWidthHeader((parseInt(width) - 10) + 'px');
      }
    });
    observer.observe(headerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  const handleGridViewClick = () => {
    setShowGridView(true);
    setShowTreeView(false);
  };
  const openDrawerPublish = (id) => {
    console.log({id})
    if (id == "editFile") {
      setEditFlag({ flag: false, value: "edit" })
    } else {
      setEditFlag({ flag: true, value: "create" })
    }
    dispatch(openDrawer(id));
  }

  const handleTreeViewClick = () => {
    setShowGridView(false);
    setShowTreeView(true);
  };
  const handleButtonClick = () => {
    setShowCode(!showCode);
  };
  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
  }
  useEffect(() => {
    if (categoryListData.categoryList.length == 0) {
      ApiService.get('category').then(res => {
        let response = res.data.data
        if (response) {
          // setCategoryList(response)
          dispatch(setCategoryDataList(response))
        }
      })
    }
  }, [categoryListData])

  useEffect(function () {
    if (userList?.userId) {
      apiCallingDashboard()
    }
  }, [userList]);

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  function apiCallingDashboard(pageNo = dashboardScroll.pageNo, scrollFlag = dashboardScroll.scrollFlag) {
    if (pageNo + 1 == 1) {
      dispatch(loaderData(true))
    }
    let params = {
      limit: 30,
      page: pageNo + 1,
      orderBy: 'asc',
      sorting: 'name'
      // sort: 'name',
      // userId: userList?.userId
    }
    // if (sortName.sortValue && sortName.orderBy || sortNameType.sortValue && sortNameType.orderBy) {
    //   params.sort = sortName.sortValue || sortNameType.sortValue;
    //   params.orderBy = sortName.orderBy || sortNameType.orderBy;
    // }
    ApiService.get('playlist', params).then(res => {
      dispatch(loaderData(false))
      let data = res.data.data
      // setLoading(false)
      dispatch(setPageNo(res.data.meta.current_page))
      setMetaData(res.data.meta)
      if (data) {
        if (scrollFlag) {
          methodsAndState.setFileCount(methodsAndState.dashboardList.length + data.length)
          methodsAndState.setDashboardList([...methodsAndState.dashboardList, ...data.map((d) => formatData(d))])
        } else {
          methodsAndState.setDashboardList(data.map((d) => formatData(d)))
          methodsAndState.setFileCount(data.length)
        }
      //   if (scrollFlag) {
      //     setDashboardList([...dashboardList, ...data])
      //     setDashboardListMaster([...dashboardListMaster, ...data])
      // } else {
      //     setDashboardList(data)
      //     setDashboardListMaster(data)
      // }
      }
    }).catch((error) => {
      console.error(error);
      // setLoading(false)
      dispatch(loaderData(false))
      methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
    })
  }

  function formatData(data) {
    const tempData = {
      // "id": (data?.pinned?.deleted_at != null) ? 'file_undefined' : (data?.pinned?.type == "directory" ? 'folder_' : 'file_') + data?.pinned?.id,
      "id": data?.id,
      "name": data?.name,
      "date": data?.updated_at,
      'created_at': data?.created_at,
      'numVideos': data?.videoPlaylist?.length,
      'thumbNailImage': data?.thumbNailImage
      // "pinnedId": data?.pinned?.id
    }
    console.log({ tempData, data })
    return tempData;
  }

  const sortByNameType = (value) => {
    sortName = {
      name: 'dashboard',
      sortValue: value,
      sortBy: sortBy == '' || sortBy == 'desc' ? 'asc' : 'desc'
    };
    setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
    setSortNameType()
    setSortNameType(sortName)
    apiCallingDashboard(0, false)
  }

  // ----------------------------- UPDATE META DATA ------------
  const updateMetaData = (count) => {
    var updatedMetaData = { ...metaData, total: metaData.total + count };
    setMetaData(updatedMetaData);
  }

  const methodsAndState = {
    sortByNameType,
    setSnackBar,
    snackBar,
    dashboardList,
    setDashboardList,
    setSelectedItems,
    selectedItems,
    // setEditFlag, 
    setFileCount,
    // editFlag,
    // tagsFlag,
    handleGridViewClick,
    handleTreeViewClick,
    showGridView,
    showTreeView,
    handleButtonClick,
    tagsFlag,
    dashboardList,
    setDashboardList,
    setSelectedItems,
    selectedItems,
    setSelectedId,
    selectedId,
    openDialogPublish,
    setSnackBar,
    snackBar,
    setBreadCrumb,
    breadCrumb,
    setEditFlag,
    editFlag, apiCallingDashboard,
    openDrawerPublish, checkboxGridHelper,
    isFixed, showCode, setShowCode, setMetaData, metaData,
    setIsFixed, setCheckedData, checkedData, setHeaderCheckStatus, headerCheckStatus, updateMetaData,
    page: PageName.PlayList
  };

  // const fetchData = () => {
  //   if (methodsAndState?.metaData?.last_page > methodsAndState?.metaData?.current_page && searchList?.searchList?.length == 0) {
  //     dispatch(setScrollFlag(true))
  //     methodsAndState.apiCallingDashboard(dashboardScroll.pageNo, true)
  //   }
  // }

  const numItemsPresent = dashboardList.length;
  const headerTags = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box>
          <DashboardHeading title={PageName.PlayList}
            // numItems={metaData?.total}
            numItems={numItemsPresent}
          />
        </Box>
      </Box>
    )
  }
  const upperDiv = document.getElementById('sticky');
  // Calculate the height of the upper div
  const upperDivHeight = upperDiv?.clientHeight - 20;

  // const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setEditFlag({ flag: false, value: "create" })
    dispatch(openDrawer("CreatePlaylist"));
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer('CreatePlaylist'));
  };

 
  return (
    <MyContext.Provider value={methodsAndState}>
      <Box>
        <Box>
          <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
            {/* <Card sx={{ p: 2, mb: 2, minHeight: '83vh' }}> */}
            <Box id="sticky" sx={{ paddingBottom: selectedItems.size ? '8px !important' : '12px !important', width: widthHeader, height: "auto !important" }}>
              <Box sx={{ flexWrap: 'wrap', width: "100%" }}>
                <Stack sx={{ backgroundColor: '#ffffff' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={8} >
                      {/* {!isFixedTop ? (selectedItems.size === 0 && headerTags()) : selectedItems.size === 0 && headerTags()} */}
                      {headerTags()}
                      {/* <VideoLibraryTools /> */}
                    </Grid>
                    <Grid item xs={9} sm={4} sx={{ zIndex: '10000 !important' }}>
                      {/* <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}> */}
                      {/* <DashboardTools /> */}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                        <Button variant="contained" size='medium' startIcon={<AddIcon />} onClick={() => methodsAndState.openDrawerPublish('CreatePlaylist')}>
                          CREATE PLAYLIST
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Box>
            <Box className="gridTabularView" sx={{ mt: upperDivHeight + "px" }}>
              {/* <Box className="gridTabularView" sx={{ mt: { xl: "3rem", lg: "3rem", md: "4rem", sm: "3rem", xs: selectedItems.size ? "5rem" : '5rem' } }}> */}
              {/* <Box className="gridTabularView" sx={{ mt: "2rem" }}> */}
              {/* {loading ? (
                <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }}>
                  {[...Array(12)].map((_, key) => (
                    <Grid key={key} item sx={{ m: '2rem' }}>
                      <Skeleton variant="rounded" width={150} height={150} /> 
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Grid>
                  ))}
                </Grid>
              ) : ( */}
                <Grid item xs={12} sm={12}>
                  <VideoLibraryGridandTree checkboxRequied={false} isPinned={false} page={PageName.PlayList} />
                </Grid>
              {/* )} */}
            </Box>
          </Card>
          <SharedDrawer id="CreatePlaylist">
            <CreatePlaylist />
          </SharedDrawer>
          <SharedDrawer id="editFile">
            {/* <FileDashboardKM /> */}
            <CreatePlaylist />
            {/* <UploadDrawer /> */}
          </SharedDrawer>
          <SharedDialog id="deleteDialog">
            <DeleteDialog />
          </SharedDialog>
          <SharedDialog id="renameDialog">
            <RenameDialog />
          </SharedDialog>
          <SharedDialog id="moveDialog">
            <MoveDialog />
          </SharedDialog>
          <TransitionsSnackbar />
        </Box>
      </Box>
    </MyContext.Provider>
  )
}

export default Playlist