import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Snackbar, Slide, Box, LinearProgress, Alert } from '@mui/material';
import PropTypes from 'prop-types';
// import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

function DownloadBarDemo({ percentage, open, handleClose,props }) {
  useEffect(() => {
    console.log("Dialog is here", { percentage, open });
  }, [percentage, open]);
  
  
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{minWidth:'50vh'}}
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose}
      TransitionComponent={Slide}
      >
      <Alert
      sx={{ backgroundColor: 'white', border: "1px solid #1D76FE"}}
        variant="filled"
        onClose={handleClose}
        // sx={{ width: '100%' }}
      >
        <Box className="linearProgressColor">
          Download Progress
          <LinearProgress sx={{ height: 20, borderRadius: 10}}  variant="determinate" value={50} />
        </Box>
      </Alert>
    </Snackbar>
  );
}

DownloadBarDemo.propTypes = {
  value: PropTypes.number.isRequired,
};

function DownloadBar() {
  const isOpen = useSelector((state) => state.downloadBar?.isOpen);
  const percentage = useSelector((state) => state.downloadBar?.percentage);

  const [open, setOpen] = useState(true); 

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <DownloadBarDemo
      percentage={percentage}
      open={isOpen}
      handleClose={handleClose}
    />
    </>
  );
}

export default DownloadBar;
