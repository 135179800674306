import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, Grid, Stack, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import MuiAlert from '@mui/material/Alert';
import { openDialog } from '../../features/dialogBox/dialogBoxSlice';
import ApiService from '../../utils/ApiService';
import MyContext from './Mycontext';
import AllVideoGrid from './AllGridView';
import DashboardHeading from './DashboardHeading';
import TrashDeleteDialog from './DialogKM/TrashDeleteDialog';
import SharedDialog from '../../features/dialogBox/dialogBox';
import TransitionsSnackbar from './Constantfile/Snackbar';
import VideoLibraryTools from './VideoLibraryTools';
import checkboxHelper from './checkboxHelper';
import transformationHelper from '../../utils/transformationHelper';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Trash = () => {
  const [dashboardList, setDashboardList] = useState([]);
  const [selectedId, setSelectedId] = useState({
    id: '0',
    value: "",
    name: "",
    type: "",
    delete: "permanent"
  });
  const [dynamicDeleteMessage, setDynamicDeleteMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState(new Set([]));
  const [selectedList, setSelectedList] = useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
  const headerRef = useRef();
  const [checkedData, setCheckedData] = useState();
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [widthHeader, setWidthHeader] = useState("100%");
  const element = document.querySelector('#headerWidthListner');
  const formatData = transformationHelper().getTransformationFunction;

const [checkboxGridHelper, setCheckboxGridHelper] = useState(
    checkboxHelper()
  );
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.target.getBoundingClientRect();
        setWidthHeader((parseInt(width) - 10) + 'px');
      }
    });
    observer.observe(headerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [element]);


  const dispatch = useDispatch();
  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
  }
  useEffect(() => {
    // const dataParams = {
    //   limit: 20,
    //   page: 1
    // }
    ApiService.get('fileArchive').then(res => {
      let data = res.data;
      if (data) {
        let mergeFileFolder = [...data.playlistData.map(v => formatData(v)), ...data.videoData.map(v => formatData(v))];

        // Sorting with null values moved to the end
        // let reverseData = mergeFileFolder.sort((a, b) => {
        //   if (a.type && b.type) {
        //     return a.type.localeCompare(b.type);
        //   }
        //   if (!a.type) return 1; // Move items with null 'type' to the end
        //   if (!b.type) return -1; // Move items with null 'type' to the beginning
        //   return 0; // Keep the same order if both 'type' values are null
        // }).map(v => {
        //   v.id = (v.type === "directory" ? 'folder_' : 'file_') + v.id;
        //   return v;
        // });

        setDashboardList(mergeFileFolder);
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
    });
  }, [])

  function restoreResponse(pid, vid, matchingData) {

    let params = {
      pids: pid,vids: vid
    }
    ApiService.get('fileFolderRestore', params).then(res => {
      setSnackBar({ dataLoad: true, message: "Video has been successfully restored.", severity: "info" })
      let mergefolderfile = matchingData.map(v => v.id);
      let deleteData = dashboardList.filter(v => !mergefolderfile.includes(v.id));
      setDashboardList(deleteData);
      setSelectedId({ id: '0', value: "", name: "", type: "" });
      setSelectedItems(new Set([]));
    }).catch((error) => {
      methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
    })

  }

  const restoreSelectedItems = () => {
    const matchingData = dashboardList.filter(item => selectedItems.has(item.id));
    const pid = [];
    const vid = [];

    for (const item of matchingData) {
      if (item.numVideos != undefined) {
        pid.push(item.pid);
      } else {
        vid.push(item.vid);
      }
    }

    restoreResponse(pid, vid, matchingData);
    // Update the selectedList state after deletion
    setSelectedList(prevSelectedList => {
      return prevSelectedList.filter(item => !selectedItems.has(item.id));
    });

    // Clear the selectedItems set after restoration
    setSelectedItems(new Set([]));
  };

  const emptyTrashHandler = () => {
    dispatch(openDialog('deletePermanentDialog'));
    setSelectedId({ delete: "all" });
  };

  const isMediumOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));

   // ----------------------------- UPDATE META DATA ------------
   const updateMetaData = (count) => {
    // var updatedMetaData = {...metaData, total: metaData.total + count};
    // setMetaData(updatedMetaData);
  }

  const methodsAndState = {
    dashboardList,
    setDashboardList,
    setDynamicDeleteMessage,
    dynamicDeleteMessage,
    setSelectedId,
    selectedId,
    restoreSelectedItems,
    loading,
    setSelectedItems,
    selectedItems,
    setSelectedList,
    selectedList,
    setSnackBar,
    snackBar,checkboxGridHelper,
    openDialogPublish, showCode, setShowCode,
    setCheckedData, checkedData, setHeaderCheckStatus, headerCheckStatus, updateMetaData
  };
  const numItemsPresent = dashboardList.length;
  const disableEmptyTrashBTN = !dashboardList.length;

  return (
    <div>
      <MyContext.Provider value={methodsAndState}>
        <Box>
          <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
            <Box id="sticky" sx={{ width: widthHeader, height: "auto !important" }}>
              <Box sx={{ flexWrap: 'wrap', width: "100%" }}>
                <Stack sx={{ backgroundColor: '#ffffff' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={9}  sx={{ height: '4rem' }}>
                      {selectedItems.size ? null : <DashboardHeading title={'Trash'} numItems={numItemsPresent} />}
                      <VideoLibraryTools />
                    </Grid>
                    <Grid item xs={2} sm={3} sx={{ zIndex: '10000 !important', alignItems: "center", justifyContent: 'flex-end', display: 'flex' }}>
                      {/* <Grid item xs={3} sx={{ justifyContent: 'flex-end', display: 'flex' }}> */}
                      <Box sx={{ marginTop: '0rem'}}>
                        {isMediumOrLarger ? (
                          <Button variant="contained" startIcon={<DeleteIcon />} onClick={emptyTrashHandler} size="medium" disabled={disableEmptyTrashBTN}>
                            EMPTY TRASH
                          </Button>
                        ) : (
                          <Button variant="contained" onClick={emptyTrashHandler} size="medium">
                            <DeleteIcon />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Box>
            <Box className="gridTabularView" sx={{ mt: "3rem" }}>
              {loading ? (
                <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }}>
                  {[...Array(12)].map((_, key) => (
                    <Grid key={key} item sx={{ m: '2rem' }}>
                      <Skeleton variant="rounded" width={150} height={150} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <AllVideoGrid isTrash={true} />
                </Grid>
              )}
            </Box>
          </Card>
        </Box>
        <TransitionsSnackbar />
        <SharedDialog id='deletePermanentDialog'>
          <TrashDeleteDialog />
        </SharedDialog>
      </MyContext.Provider>
    </div>
  );
};

export default Trash;
