import ApiService from "../../utils/ApiService";
import React from "react";
import { useState } from 'react';
import { getSelfToken } from "../../_helper/secureToken";
// import { useDispatch } from "react-redux";
import { openDownloadBar, closeDownloadBar, updateDownloadBar } from '../../features/downloadBar/DownloadBarSlice';

const openDownload = () => {

    // const dispatch = useDispatch();

    function extractVideoFileFormat(inputString) {
        const regex = /\.(mp4|webm|ogg|mov|avi)$/i; // Case-insensitive match for common video formats
        const match = inputString.match(regex);
      
        if (match) {
          return match[0]; // Return the entire matched extension (e.g., ".mp4")
        } else {
          return null; // No video format found
        }
      }

    const downloadFile = async (dataFile, selectedData, setOpenText,setDownloadText, dispatch) => {

        // const canOpen = ['image/png', 'image/jpg', 'image/jpeg', ];
        const paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
        // if (dataFile == 'open') {
        //     setOpenText('Opening...')
        // } else {
            setDownloadText('Downloading...')
        // }
          dispatch(openDownloadBar());
          console.log("Open should be called");
          // fetch('http://streamapi.eela.tech/api/stream?file=test2.mp4')
          // fetch(selectedData.videoUrl)
          // .then(response => response.blob())
          // .then(blob => {
          //   const url = URL.createObjectURL(blob);
          //   const link = document.createElement('a');
          //   link.href = url;
          //   const videoType = extractVideoFileFormat(selectedData.video);
          //   link.setAttribute('download', `${selectedData.name}${videoType}`); // Adjust filename as needed
          //   link.click();
          //   var formData = {
          //     activityName: dataFile == 'download' ? "Downloaded" : "Opened",
          //     mediaType: selectedData.type,
          //     mediaId: paramsid,
          //   }
            
          //   ApiService.post('filemanagerActivity', formData).then(res => {
          //       let data = res.data.data
          //   })
          //   URL.revokeObjectURL(url);
          // })
          // .catch(error => console.error('Error downloading video:', error));


          const xhr = new XMLHttpRequest();
          xhr.open('GET', selectedData.videoUrl, true);
          xhr.responseType = 'blob';

          xhr.onprogress = (event) => {
            console.log({event})
            if (event.lengthComputable) {
              const percentage = (event.loaded / event.total) * 100;
              console.log(`Downloaded: ${percentage.toFixed(2)}%`);
              dispatch(updateDownloadBar({percentage: percentage.toFixed(2)}))
              console.log("Progress should be called");
            }
          };

          xhr.onload = () => {
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const videoType = extractVideoFileFormat(selectedData.video);
            link.setAttribute('download', selectedData.name); // Adjust filename as needed
            link.click();
            var formData = {
              activityName: dataFile == 'download' ? "Downloaded" : "Opened",
              mediaType: selectedData.type,
              mediaId: paramsid,
            }
            
            ApiService.post('filemanagerActivity', formData).then(res => {
                let data = res.data.data
            })
            URL.revokeObjectURL(url);
            dispatch(closeDownloadBar());
            console.log("Close should be called");
          };

          xhr.onerror = () => {
            dispatch(closeDownloadBar());
            console.log("Close should be called");
            console.error('Error downloading video');
          };

          xhr.send();
    
    };

    return { downloadFile };
};

export default openDownload;