import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, Stack, Grid, Button,Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import SharedDialog from '../../features/dialogBox/dialogBox';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer, closeDrawer } from "../../features/drawers/drawerSlice";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import UploadDrawer from '../../components/drawerBody/Tstream/FileLibrary/StreamDrawer/UploadDrawer';
import FolderDashboardKM from './DialogKM/FolderDashboardKM';
import FileDashboardKM from './DrawerDialogKM/FileDashboardKM';
import DashboardHeading from './DashboardHeading';
import DashboardTools from './DashboardTools';
import UploadVideo from './UploadVideo';
import MyContext from './Mycontext';
import VideoLibraryGridandTree from './VideoLibraryGridandTree';
import ApiService from '../../utils/ApiService';
import DeleteDialog from './DialogKM/DeleteDialog';
import RenameDialog from './DialogKM/RenameDialog';
import RecentTags from './RecentTags';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TransitionsSnackbar from './Constantfile/Snackbar';
import { loaderData } from '../../features/loader/loaderDashboard';
import MenuBreadcrumb from './MenuBreadcrumb';
import { setPageNo, setScrollFlag } from '../../features/dashboardData/dashboardData';
import VideoLibraryTools from './VideoLibraryTools';
import { setRecentTags } from '../../features/dashboardData/recentTagsData';
import { setCategoryDataList } from '../../features/commonDataSlice/categoryData';
import checkboxHelper from './checkboxHelper';
import MoveDialog from './DialogKM/MoveDialog';
import { removeSearch, removeSearchList, searchDataList, searchValueSet } from '../../features/headerSearch/headerSearch';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import RecommendedVideo from './RecommendedVideo';
import PageName from '../../utils/getPageName';

const VideoLibrary = () => {
    const navigate = useNavigate();
    const _ = require("lodash");
    let { id } = useParams();
    const locationData = useLocation();
    //Dialog Box //
    const dispatch = useDispatch();
    const openDialogPublish = (id) => {
        setAnchorEl(null);
        dispatch(openDialog(id));
    }
    const [showCode, setShowCode] = useState(false);
    const [showGridView, setShowGridView] = useState(true);
    const [showTreeView, setShowTreeView] = useState(false);
    const [dashboardList, setDashboardList] = useState([]);
    const [dashboardListMaster, setDashboardListMaster] = useState([]);
    const [dynamicDeleteMessage, setDynamicDeleteMessage] = useState('');
    const [breadCrumb, setBreadCrumb] = useState([{ id: 0, name: PageName.AllVideos }]);
    const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
    console.log("a1",selectedId)
    const searchList = useSelector((state) => (state.search));
    console.log(searchList.searchList.length)
    const [tagsFlag, setTagsFlag] = useState(true);
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [selectedList, setSelectedList] = useState([]);
    const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
    const [isFixedTop, setisFixedTop] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [metaData, setMetaData] = useState();
    const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
    const dashboardScroll = useSelector((state) => (state.dashboardScroll));
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [checkedData, setCheckedData] = useState();
    const [sortNameType, setSortNameType] = useState({});
    const [sortBy, setSortBy] = useState(''); // Initialize the sorting order to 'asc'
    var sortName = { name: '', sortValue: '', sortBy: '' }
    const recentTags = useSelector((state) => (state.recentTag?.recentTags));
    const categoryListData = useSelector((state) => (state.category));
    const currentRoute = useSelector((state) => (state.routing.currentRoute));
    const [filterOn, setFilterOn] = useState(false);
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(
        checkboxHelper()
    );
    const userList = useSelector((state) => state?.user?.userListName);
    const [hardRefresh, setHardRefresh] = useState(true);

    const [recommandedVideos, setRecommandedVideos] = useState([]);
    console.log(('a1',recommandedVideos    ))

    const getRecommandedVideo = (videos) => {
        setRecommandedVideos(videos)
        console.log(('b1',setRecommandedVideos))
    }
    // const previousArrayLengthRef = useRef(null);
    // const previousArrayLength = usePrevious(dashboardList.length);

    // useEffect(() => {

    //     // Check if the array length has changed
    //     if (previousArrayLength !== dashboardList.length) {
    //       // Perform operations based on the previous and current array lengths
    //       console.log(`Previous array length: ${previousArrayLength}`);
    //       console.log(`Current array length: ${dashboardList.length}`);
    //     }

    //     // Update the previous array length reference
    //     previousArrayLengthRef.current = dashboardList.length;
    // }, [dashboardList]);

    //Drawer //
    const openDrawerPublish = (id) => {
        if (id == "editFile") {
            setEditFlag({ flag: false, value: "edit" })
        } else {
            setEditFlag({ flag: true, value: "create" })
        }
        setAnchorEl(null);
        dispatch(openDrawer(id));
        // setSelectedItems(new Set([]))
    }



    const [filterCategory, setFilterCategory] = useState([]);
    const selectRef = useRef();
    const headerRef = useRef();
    const element = document.querySelector('#headerWidthListner');
    const [widthHeader, setWidthHeader] = useState("100%");
    // const listener = useRef(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.target.getBoundingClientRect();
                setWidthHeader((parseInt(width) - 10) + 'px');
            }
        });
        observer.observe(headerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [element]);

    useEffect(() => {
        if(!hardRefresh){
            setHardRefresh(true);
        }
    }, [hardRefresh])

    const handleButtonClick = () => {
        setShowCode(!showCode);
    };

    const handleGridViewClick = () => {
        setShowGridView(true);
        setShowTreeView(false);
    };

    const handleTreeViewClick = () => {
        setShowGridView(false);
        setShowTreeView(true);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setIsTourOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //single Select//
    const [lastModified, setLastModified] = React.useState('');

    const handleModifiedChange = (event) => {
        setLastModified(event.target.value);
    };
    const [location, setLocation] = React.useState('');

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    const [practice, setPractice] = React.useState('');

    const handlePracticeChange = (event) => {
        setPractice(event.target.value);
    };
    const [stage, setStage] = React.useState('');

    const handleStageChange = (event) => {
        setStage(event.target.value);
    };
    const [technology, setTechnology] = React.useState('');

    const handleTechnologyChange = (event) => {
        setStage(event.target.value);
    };

    useEffect(() => {
        if (breadCrumb.length) {
            if (breadCrumb[0].name == "Pinned" && currentRoute == '/') {
                // console.log("breadCrumb dashboard", { currentRoute, breadCrumb });
                dispatch(setScrollFlag(false))
                dispatch(setPageNo(0))
                setBreadCrumb([{ id: 0, name: "File Library" }])
                apiCallingDashboard(0, false)
            }
        }
    }, [currentRoute])

    useEffect(() => {
        if (currentRoute == '/') {
            if (breadCrumb.length > 1) {
                localStorage.setItem('myContextState', JSON.stringify(breadCrumb));
                localStorage.setItem('myContextStateMaster', JSON.stringify(breadCrumb));
            }
        }
    }, [breadCrumb])

    useEffect(() => {
        if (window.location.pathname != "/") {
            const savedState = JSON.parse(localStorage.getItem('myContextState'));
            if (currentRoute == '/pinned') {
                savedState[0].name = "Pinned";
                setBreadCrumb(savedState)
            } else {
                setBreadCrumb(savedState)
            }
        }
    }, []);

    // ---------------- API CALLING TO GET FOLDER AND FILES ------------------
    function apiCallingDashboard(pageNo = dashboardScroll.pageNo, scrollFlag = dashboardScroll.scrollFlag) {
        // if (searchList.searchList.length == 0 && searchList.searchValue == "") {
        if (window.location.pathname == "/") {
            id = "0"
        }
        let idfolder = id != undefined ? id : selectedId.id
        const paramsid = idfolder.includes('_') ? idfolder.split('_')[1] : idfolder;
        if (pageNo + 1 == 1) {
            dispatch(loaderData(true))
        }

        const dataParams = {
            limit: 30,
            page: pageNo + 1,
            orderBy: sortName.sortBy || 'asc',
            sorting: 'name',
            related: ['streamCategory','streamSubcategory','modifyBy']
            // related: 'streamSubcategory'
            // related: 'type,name',
            // userId: userList?.userId
        }

        if (sortName.sortValue && sortName.sortBy || sortNameType.sortValue && sortNameType.sortBy) {
            // dataParams.related = sortName.sortValue || sortNameType.sortValue;
            // dataParams.sortBy = sortName.sortBy || sortNameType.sortBy;
        }

        ApiService.get('folderpermission', dataParams).then(res => {
            dispatch(loaderData(false))
            let data = res.data.data.map((v => {
                return {
                    ...v,
                    // name: v.video
                }
            }))
            dispatch(setPageNo(res.data.meta.current_page))
            setMetaData(res.data.meta)
            console.log(res.data.meta)
            if (data) {
                console.log({ data })
                // let reverseData = data.map(v => {
                //     // v.id = v.id;
                //     // v.modifyBy = v.type == "directory" ? v.folderModifyBy : v.fileModifyBy;
                //     // v.tags = v.type == "directory" ? [] : v.tags;
                //     return v;
                // });
                if (scrollFlag) {
                    setDashboardList([...dashboardList, ...data])
                    setDashboardListMaster([...dashboardListMaster, ...data])
                } else {
                    setDashboardList(data)
                    setDashboardListMaster(data)
                }

            }
        }).catch((error) => {
            dispatch(loaderData(false))
            console.log(error);
            setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
        // }
        
    }
    useEffect(() => {
        const handleBackButton = (event) => {
            let browserEvent = event?.state?.usr
            if (browserEvent != undefined) {
                setBreadCrumb(browserEvent)
            } else {
                navigate('/')
                apiCallingDashboard(0, false)
                setBreadCrumb([{ id: 0, name: "File Library" }])
            }

        };
        window.addEventListener('popstate', handleBackButton);
        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);


    const sortByNameType = (value) => {
        sortName = {
            name: 'dashboard',
            sortValue: value,
            sortBy: sortBy == '' || sortBy == 'desc' ? 'asc' : 'desc'
        };
        setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
        setSortNameType()
        setSortNameType(sortName)
        apiCallingDashboard(0, false)
    }


    useEffect(() => {
        // if (selectedId.value == "folder" && id != undefined) {
            apiCallingDashboard(0, false)
        // }
    }, [])

    useEffect(() => {
        console.log(searchList)
        if (searchList.searchList[0] == "error") {
            setSnackBar({ dataLoad: true, message: "Search Api Failed", severity: "error" })
        } 
        // else if (searchList.searchValue == "") {
        //             dispatch(setScrollFlag(false))
        //             // console.debug("aaaaaaaa searchlist api call first")
        //             apiCallingDashboard(0, false)
        //         }
        else {
            // if (searchList.searchList.length) {
                // let reverseData = _.orderBy(searchList.searchList, ['type'],
                //     ['asc']);
                setDashboardList(searchList.searchList)
            // }
            // else {
            //     if (searchList.searchList.length == 0 && searchList.searchValue == "") {
            //         dispatch(setScrollFlag(false))
            //         // console.debug("aaaaaaaa searchlist api call first")
            //         apiCallingDashboard(0, false)
            //     }
            //     if (searchList.searchList.length == 0 && searchList.searchValue != "") {
            //         // console.debug("aaaaaaaa searchlist api call if second")
            //         setDashboardList(searchList.searchList)
            //         navigate('/');
            //         setBreadCrumb([{ id: 0, name: "File Library" }])
            //     }
            // }

        }
    }, [searchList])

    function recentTagsApi() {
        ApiService.get('recentTags').then((res) => {
            let data = res.data.data;
            if (data.length) {
                dispatch(setRecentTags(data))
            } else {
                dispatch(setRecentTags(['nodata']))
            }
        });
    }
    useEffect(() => {
        if (recentTags.length == 0) {
            recentTagsApi();
        }
    }, [])

    useEffect(() => {
        if (categoryListData.categoryList.length == 0) {
            ApiService.get('category').then(res => {
                let response = res.data.data
                if (response) {
                    filterCategoryData(response)
                    dispatch(setCategoryDataList(response))
                }
            })
        } else {
            filterCategoryData(categoryListData.categoryList)
        }
    }, [categoryListData])

    const filterCategoryData = (data) => {
        data.map((temp) => {
            let description = temp.description;
            let subCategoryOption = temp.subCategory;
            setFilterCategory(category => [...category, { name: description, subCategory: [], subCategoryOption: subCategoryOption }])
            return temp;
        })
    }

    // --------------------- CATEGORY FILTER METHOD -------------

    function hasIntersection(arr1, arr2) {
        return arr1.filter(id => arr2.includes(id)).some(id => true);
    }

    function onChangeSelectCategory(idsTags) {

        if(idsTags.length){
            setFilterOn(true);
            let filterredData = dashboardListMaster.filter( v => {
                let tagIds = v.tags.map(t => t.id);
                return hasIntersection(idsTags, tagIds);
            })
            // console.log({dashboardListMaster,filterredData})
            setDashboardList(filterredData);
        } else {
            setDashboardList(dashboardListMaster);
            setFilterOn(false);
        }
        // console.log('Jawab', { data: data, value: value });

        // if (!Array.isArray(data)) {
        //     if (data != null) {
        //         data = [data]
        //         console.log('b1',data)
        //     }
        //     else {
        //         data = []
        //     }
        // }
        // console.log('Jawab', { data });
        // let index = filterCategory.findIndex((temp) => temp.name == value.description);
        // console.log('Jawab',{index})
        // let newArray = [...filterCategory];
        // console.log('Jawab',{newArray})
        // newArray[index] = { ...newArray[index], subCategory: [...data] };
        // setFilterCategory(newArray);
        // console.log('b1',)
        // let concatenatedFiles = [];
        // for (let i = 0; i < newArray.length; i++) {
        //     const element = newArray[i]?.subCategory;
            // if (element.length) {
            //     setFilterOn(true);
            //     for (let j = 0; j < element.length; j++) {
            //         let subcategory = element[j]?.files;
            //         if (subcategory != undefined || subcategory != null) {
            //             concatenatedFiles = concatenatedFiles.concat(subcategory);
            //             concatenatedFiles = Object.values(concatenatedFiles.reduce((accumulatedElements, currentElement) => {
            //                 if (!accumulatedElements[currentElement.id]) {
            //                     accumulatedElements[currentElement.id] = currentElement;
            //                 }
            //                 return accumulatedElements;
            //             }, {})).sort((a, b) => a.name.localeCompare(b.name))
            //             console.log("Jawab",{concatenatedFiles})
            //             // setDashboardList(concatenatedFiles)
            //         }
            //     }
            // }
        //     if (newArray.every(item => item.subCategory.length === 0)) {
        //         setDashboardList(dashboardListMaster)
        //         setFilterOn(false);
        //     }
        // }
    }

    // ----------------------------- BREADCRUMB FOLDER DROPDOWN CLICK ------------

    const openFolderBreadcrumb = (data) => {
        dispatch(setScrollFlag(false))
        dispatch(setPageNo(0))
        let sliceBreadcrumb = breadCrumb.slice(-1)
        if (data.id != sliceBreadcrumb[0].id) {
            setSelectedId({ id: data.id, value: 'folder' });
            if (data.id == 0) {
                navigate(currentRoute == '/pinned' ? '/pinned' : '/');
                // console.debug("aaaaaaaa breadcrumb api call")
                apiCallingDashboard(0, false)
            } else {
                const paramsid = typeof data?.id === 'string' && data?.id.includes('_') ? data?.id.split('_')[1] : data?.id;
                navigate('/folder/' + paramsid);
            }
            let breadArray = breadCrumb.findIndex(d => d.id == data.id);
            let popBreadValue = breadCrumb.slice(0, breadArray + 1)
            setBreadCrumb(popBreadValue)
            setSelectedItems(new Set([]))

        }
    }

    // ----------------------------- UPDATE META DATA ------------
    const updateMetaData = (count) => {
        var updatedMetaData = { ...metaData, total: metaData.total + count };
        setMetaData(updatedMetaData);
    }

    // const addNewVideo = (video, isRecommanded) => {
    //     let tempDashboardList = [...dashboardList];
    //     tempDashboardList.unshift(video);
    //     setDashboardList(tempDashboardList);
    //     setDashboardListMaster([video,...dashboardListMaster])
    //     setMetaData({ ...metaData, total: metaData.total + 1 })
    //     console.log("Roar", {tempDashboardList});
    //     if(isRecommanded){
    //         setHardRefresh(false);
    //     }
    //     console.log({video, isRecommanded})
    // }

    const addNewVideo = (video, isRecommanded) => {
        let tempDashboardList = [...dashboardList];
        const updatedVideo = {
            ...video,
            isUpdated: true, // Add the isUpdated property
          };
        tempDashboardList.unshift(updatedVideo);
        setDashboardList(tempDashboardList);
        setDashboardListMaster([updatedVideo,...dashboardListMaster])
        setMetaData({ ...metaData, total: metaData.total + 1 })
        console.log("Roar", {tempDashboardList});
        if(isRecommanded){
            setHardRefresh(false);
        }
        console.log({updatedVideo, isRecommanded})
    }
    const editNewVideo = (video, isRecommanded) => {
        let tempDashboardList = [...dashboardList];
        const index = tempDashboardList.findIndex(item => item.id === video.id);
        console.log("t1",index)
        if (index !== -1) { 
            const updatedVideo = {
                ...video,
                isUpdated: true 
            };
            tempDashboardList.splice(index, 1, updatedVideo); 
            setDashboardList(tempDashboardList);
            setDashboardListMaster([updatedVideo, ...dashboardListMaster]); 
            console.log("Roar", { tempDashboardList });
            if (isRecommanded) {
                setHardRefresh(false);
            }
            console.log({ updatedVideo, isRecommanded });
        } else {
            console.error("Video not found in dashboardList");
        }
    }
    
    // ------------------- CONTEXT API METHODS AND STATES ---------------------

    const methodsAndState = {
        handleGridViewClick,
        handleButtonClick,
        handleTreeViewClick,
        open,
        handleClick,
        anchorEl,
        handleClose,
        openDialogPublish,
        openDrawerPublish,
        handleModifiedChange,
        lastModified,
        handleLocationChange,
        location,
        handlePracticeChange,
        practice,
        handleStageChange,
        stage,
        handleTechnologyChange,
        technology,
        showGridView,
        showTreeView,
        dashboardList,
        setDashboardList,
        setSelectedId,
        selectedId,
        setDynamicDeleteMessage,
        dynamicDeleteMessage,
        breadCrumb,
        setBreadCrumb,
        tagsFlag,
        // setCategoryList,
        // categoryList,
        setSelectedItems,
        selectedItems,
        setSelectedList,
        selectedList,
        setSnackBar,
        snackBar,
        onChangeSelectCategory,
        selectRef,
        openFolderBreadcrumb,
        isFixed, setCheckedData, checkedData,
        // sortNameType,
        setIsFixed, showCode, setShowCode, sortByNameType,
        setEditFlag, apiCallingDashboard, setHeaderCheckStatus,
        editFlag, setMetaData, metaData, filterCategory, headerCheckStatus, checkboxGridHelper, updateMetaData,
        page: PageName.AllVideo,
        addNewVideo,
        editNewVideo
    };
    // const numItemsPresent = dashboardList.length;

    const breadCrumbCom = () => {
        return (
            <Breadcrumbs aria-label="breadcrumb">
                {breadCrumb.length > 2 ? <MenuBreadcrumb move={false} /> : ""}
                {breadCrumb.slice(-2).map((file, key) => (

                    <Box key={key}
                        onClick={() => openFolderBreadcrumb(file)}
                        sx={{ cursor: breadCrumb.indexOf(file) === breadCrumb.length - 1 ? 'default' : 'pointer', zIndex: '99999' }}>
                        <DashboardHeading
                            title={file.name }
                            // numItems={breadCrumb.indexOf(file) === breadCrumb.length - 1 ?
                            //     metaData?.total : undefined}
                            numItems={
                                searchList.searchList.length != 0 ? searchList.searchList.length :
                                    breadCrumb.indexOf(file) === breadCrumb.length - 1 ?
                                        filterOn ? dashboardList.length :
                                            metaData?.total : undefined}
                        />
                        {/* {console.log({searchList,breadCrumb,file,metaData,count: searchList?.searchValue != "" ? searchList.searchList.length :
                            breadCrumb.indexOf(file) === breadCrumb.length - 1 ?
                            metaData?.total : undefined, first: searchList?.searchList.length, second:
                            breadCrumb.indexOf(file) === breadCrumb.length - 1, third:
                            metaData?.total })} */}
                    </Box>
                ))}
            </Breadcrumbs>
        )
    }
    const upperDiv = document.getElementById('sticky');
    // Calculate the height of the upper div
    const upperDivHeight = upperDiv?.clientHeight - 20;

    const handleViewAllClick = () => {
        // Navigate to the ViewRecommendedVideo page
        window.location.href = '/ViewRecommendedVideo'
    };

    return (
            <>
        <MyContext.Provider value={methodsAndState}>
                <Box >
                    <Box>
                        <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
                            <Box id="sticky"
                                sx={{
                                    paddingBottom: selectedItems.size
                                        ? { xs: '0px !important', sm: '20px !important', md: '20px !important', lg: "0px !important", xl: "0px !important" }
                                        : { xs: '6px !important', sm: '25px !important', md: '25px !important', lg: "0px !important", xl: "0px !important" }, width: widthHeader, height: "auto !important"
                                }}
                            >
                                <Box id="header">
                                    {isFixedTop || currentRoute != '/' ? null :
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={8} sm={8}>
                                                    <Box><RecentTags /></Box>
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <UploadVideo />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                </Box>
                                <Box>
                                    <Box sx={{ flexWrap: 'wrap' }}>
                                        <Stack sx={{ backgroundColor: '#ffffff' }}>
                                            <Grid container spacing={2} sx={{ mt: 0, }}>
                                                <Grid item xs={8} sm={8} sx={{ height: { md: '4rem', lg: "4rem", xl: "4rem" },display:'flex',alignItems:'center' }}>
                                                {/* <Typography variant="h6">{breadCrumbCom()}</Typography> */}
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                            <Box
                                                                sx={{ zIndex: '99999' }}>
                                                                <DashboardHeading
                                                                    title={"Recommended Videos  "}
                                                                    numItems={recommandedVideos.length}
                                                                />
                                                            </Box>
                                                    </Breadcrumbs>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ zIndex: '10000 !important', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}>
                                                        <Button endIcon={<ArrowOutwardIcon />} size="large" onClick={handleViewAllClick}  sx={{ textTransform:'none !important',color:'#212121 !important'}}>
                                                            View all
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        {hardRefresh && (
                                        <RecommendedVideo getRecommandedVideo={getRecommandedVideo} />
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{ flexWrap: 'wrap' }}>
                                        <Stack sx={{ backgroundColor: '#ffffff' }}>
                                            <Grid container spacing={2} sx={{ mt: 0, }}>
                                                <Grid item xs={12} sm={8} sx={{ height: { md: '4rem', lg: "4rem", xl: "4rem" } }}>
                                                    {isFixedTop ? (selectedItems.size === 0 && breadCrumbCom()) :
                                                        (selectedItems.size === 0 || !recentTags.length) && breadCrumbCom()}
                                                    <VideoLibraryTools />
                                                </Grid>
                                                <Grid item xs={12} sm={4} sx={{ zIndex: '10000 !important' }}>
                                                    <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}>
                                                        <DashboardTools />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Box>

                                </Box>
                            </Box>
                            <Box className="gridTabularView" sx={{ mt: upperDivHeight + "px" }}>
                                {/* <Box className="gridTabularView" sx={{ mt: { xl: "6rem", lg: "6rem", md: "7rem", sm: "7rem", xs: selectedItems.size ? "12rem" : '8rem' } }}> */}
                                <Grid container>
                                    <Grid item xs={12} sm={12} >
                                        {hardRefresh && (
                                        <VideoLibraryGridandTree page={PageName.AllVideos} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                        <SharedDialog id="folderDialog">
                            <FolderDashboardKM />
                        </SharedDialog>
                        <SharedDrawer id="createFile">
                            <UploadDrawer />
                        </SharedDrawer>
                        <SharedDrawer id="editFile">
                            {/* <FileDashboardKM /> */}
                            <UploadDrawer />
                        </SharedDrawer>
                        <SharedDialog id="deleteDialog">
                            <DeleteDialog />
                        </SharedDialog>
                        <SharedDialog id="renameDialog">
                            <RenameDialog />
                        </SharedDialog>
                        <SharedDialog id="moveDialog">
                            <MoveDialog />
                        </SharedDialog>

                        <TransitionsSnackbar />
                    </Box>
                </Box>
        </MyContext.Provider >
            </>
    )
}

export default VideoLibrary