import React, { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import { Box, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
const Apptour = () => {
    const recentTags = useSelector((state) => (state.recentTag?.recentTags));
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(1);
    const [permissionValue, setPermissionValue] = React.useState(1);
    const [topleft, setTopleft] = React.useState({
        top: '4rem',
        left: '5rem'
    });
    const [focusedContent, setFocusedContent] = React.useState({
        height: '65px',
        width: '65px'
    });
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXl = useMediaQuery(theme.breakpoints.down('xl'));

    const [tourSteps, setTourSteps] = useState([
        {
            content: (
                <Box className="tour-step" sx={{ padding: "2rem 0", width: "100%" }}>
                    <Typography variant='h6'>Expand for Menu: </Typography>
                    <Typography>Click here to open the menu options. </Typography>
                </Box>
            ), target: 'sideBarExpand', topleftValue: {
                top: '4rem',
                left: '5rem'
            }, focusedContentValue: {
                // top: '0',
                // left: '0',
                height: '65px',
                // width: '65px'
            }
        },
        {
            content: (
                <Box className="tour-step" sx={{ padding: "2rem 0", width: "100%" }}>
                    <Typography variant='h6'>Recent Tags:</Typography>
                    <Typography>These tags are user-created and represent recent additions. </Typography>
                </Box>
            ), target: 'tagsContainer',
            topleftValue: {
                top: '10rem',
                left: '25%'
                // left: '15rem'
            }, focusedContentValue: {
                height: '65px',
                // width: '85%'
            }
        },
        {
            content: (
                <Box className="tour-step" sx={{ padding: "2rem 0", width: "100%" }}>
                    <Typography variant='h6'> Category Filter: </Typography>
                    <Typography>This allows you to filter the content by category.</Typography>
                </Box>
            ), target: 'categoryFilter',
            topleftValue: {
                top: '12rem',
                right: '15rem'
            }, focusedContentValue: {
                height: '65px',
                // width: '4em'
            }
        },
        {
            content: (
                <Box className="tour-step" sx={{ padding: "2rem 0", width: "100%" }}>
                    <Typography variant='h6'>View Options: </Typography>
                    <Typography>Toggle between grid view and tabular view for your file and folder layout. </Typography>
                </Box>
            ), target: 'gridTableView',
            topleftValue: {
                top: '12rem',
                right: '8.5rem'
            }, focusedContentValue: {
                height: '65px',
                // width: '7em'
            }
        },
        {
            content: (
                <Box className="tour-step" sx={{ padding: "2rem 0", width: "100%" }}>
                    <Typography variant='h6'>File/Folder Actions:</Typography>
                    <Typography> Here you can - </Typography>

                    <Typography>   a). Create a New Folder</Typography>

                    <Typography>   b). Upload a New File </Typography>
                </Box>
            ), target: 'folderFileOption',
            topleftValue: {
                top: '12rem',
                right: '2.3rem'
            }, focusedContentValue: {
                height: '65px',
                // width: '11%'
                // width: isSm ? '11%' : isXl ? '5%' : isLg ? '8%' : '8%'
            }
        },
    ]);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if ((recentTags.length || recentTags[0] == 'nodata') && window.location.pathname == "/" && !isMd) {
            let countcurrent = 0
            setOpen(true)
            // document.body.style.overflow = 'hidden';
            callbackCount(countcurrent)
        // Do not know why this line of code is present but it is creating issues, sometimes scroll stops because of this
        // } else {
        //     if (window.location.pathname == "/") {
        //         document.body.style.overflow = 'hidden';
        //     } else {
        //         document.body.style.overflow = 'auto';
        //     }
        }
    }, [recentTags])

    const handleNextStep = () => {
        if (currentStep < tourSteps.length - 1) {
            let countcurrent = currentStep + 1
            setCurrentStep(currentStep + 1);
            setCount(count + 1)
            callbackCount(countcurrent)
        }
    };

    const callbackCount = (count) => {
        if(count == 0){
            document.body.style.overflow = 'hidden';
        }
        const div = document.getElementById(tourSteps[count].target);
        const rect = div.getBoundingClientRect();
        const focusedContentValue = tourSteps[count].focusedContentValue;
        const updatedFocusedContentValue = {
            ...focusedContentValue,
            top: (parseFloat(rect.top) - 15) + 'px',
            left: (parseFloat(rect.left) - 10) + 'px',
            width: (parseFloat(rect.width) + 20) + 'px',
        };
        if (document.getElementById('folderFileOption') == null && permissionValue == 1 && count == 2) {
            setPermissionValue(permissionValue + 1);
            const newArray = tourSteps.slice(0, -1);
            setTourSteps(newArray)
        }
        setTopleft(tourSteps[count].topleftValue)
        // setTopleft({top: (parseFloat(rect.bottom)) + 'px', left: (parseFloat(rect.right)) + 'px'})
        console.log({updatedFocusedContentValue,focusedContentValue,rect,div})
        setFocusedContent(updatedFocusedContentValue)
    }

    const handlePrevStep = () => {
        if (currentStep > 0) {
            let countcurrent = currentStep - 1
            setCurrentStep(currentStep - 1);
            setCount(count - 1)
            callbackCount(countcurrent)
        }
    };

    const handleSkipTour = () => {
        setOpen(false)
        document.body.style.overflow = 'auto'
    };

    const badgetStyle = {
        width: "100%",
        '& .MuiBadge-badge': {
            position: 'absolute',
            left: '-26px',
            top: '5px',
            padding: '1rem',
            height: '35px',
            width: '35px',
            borderRadius: '50%',
            fontSize: '20px',
        }
    }

    return (
        open && <Box>
            <Box className="react-joyride__overlay">
                <Box className="react-joyride__spotlight" sx={focusedContent} ></Box>
            </Box>
            <Box className={"tour-guide"} sx={topleft} >
                <IconButton onClick={() => handleSkipTour()} className='reactour-close sc-bxivhb sc-bdVaJa cYQqRL' aria-label="close-image" component="label" color='var(--color-black)'>
                    <CloseIcon sx={{ color: 'var(--color-black)' }} />
                </IconButton>
                <Badge sx={badgetStyle} badgeContent={count} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }} color="primary">
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                            {tourSteps[currentStep].title}
                            {tourSteps[currentStep].content}
                            <Box className="tour-button-container">
                                <Button onClick={handlePrevStep} disabled={currentStep > 0 ? false : true} >Previous</Button>
                                {/* {console.log(currentStep, tourSteps.length - 2, permissionValue)} */}
                                {(currentStep < tourSteps.length - 1) && (
                                    //  {(currentStep < tourSteps.length - 1 || (currentStep < tourSteps.length - 2 && !permissionValue)) ? (
                                    <Button onClick={handleNextStep}>Next</Button>
                                )}
                                {(currentStep === tourSteps.length - 1) && (
                                    //  {/* {(currentStep === tourSteps.length - 1 || (currentStep === tourSteps.length - 2 && permissionValue)) ? ( */}
                                    <Button onClick={handleSkipTour}>Finish</Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Badge>
            </Box>
        </Box>
    );
};

export default Apptour;
