function CheckPermission(arr1, arr2) {
    if (!arr1 || !arr2) return
    let result = false;
    arr1.forEach((e1, i) => {
        arr2.forEach((e2) => {
            if (e1.name == e2) {
                result = true
                return result;
            }
        });
    });
    return result
}
// function Can({ yes = null, no = null, requirePermissions, userPermissions , ...props }) {
//     return (
//         compare(userPermissions, requirePermissions) == true ? yes : no
//     )
// }

export default CheckPermission;

