import React from 'react'
import { Box, Grid,Switch,FormGroup,FormControlLabel } from '@mui/material'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';

const VideoSetting = () => {
  return (
    <>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box>
       <Box sx={{display:'flex'}}>
        <Box><ContactMailOutlinedIcon/></Box>
        <Box sx={{ml:1}}>Comments</Box>
       </Box>
      </Box>
      <Box>
      <FormGroup>
      <FormControlLabel control={<Switch defaultChecked />}/>
    </FormGroup>
      </Box>
      </Box>
      <Box sx={{display:'flex', justifyContent:'space-between',my:2}}>
      <Box>
       <Box sx={{display:'flex'}}>
        <Box><WarningAmberIcon/></Box>
        <Box sx={{ml:1}}>Transcript and Caption</Box>
       </Box>
      </Box>
      <Box>
      <FormGroup>
      <FormControlLabel control={<Switch defaultChecked />}/>
    </FormGroup>
      </Box>
      </Box>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
      <Box>
       <Box sx={{display:'flex'}}>
        <Box><EventAvailableIcon/></Box>
        <Box sx={{ml:1}}>About Video</Box>
       </Box>
      </Box>
      <Box>
      <FormGroup>
      <FormControlLabel control={<Switch />}  />
    </FormGroup>
      </Box>
      </Box>
    </>
  )
}

export default VideoSetting