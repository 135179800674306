import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import MyContext from './Mycontext';
import DeleteIcon from '@mui/icons-material/Delete';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DownloadIcon from '@mui/icons-material/Download';
// import Can from './Permission/Can';
import CheckPermission from './Permission/CheckPermission';
import { userPermissions } from './Permission/required.permission';
import { useState } from 'react';
import openDownload from './openDownload';
import InfoIcon from '@mui/icons-material/Info';
import PushPinIcon from '@mui/icons-material/PushPin';
import unpin from '../../assets/images/KM-Manager/unpin.svg';
import PinUnpin from './PinUnpin';
import { IconButton } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import encriptionHelper from '../../utils/encriptionHelper';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageName from '../../utils/getPageName';
import MyContext from './Mycontext';

function MenuBar(props) {

  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const methodsAndState = React.useContext(props?.context || MyContext);
  const userPermissionsListRedux = useSelector((state) => (state.permission));
  const [userPermissionsList, setUserPermissionsList] = React.useState([]);
  const [matchingSelectedData, setMatchingSelectedData] = useState([]);
  const [openText, setOpenText] = useState('Open');
  const [downaloadText, setDownloadText] = useState('Download');
  const pinunpinshow = matchingSelectedData.every(item => item.pinned === true) || window.location.pathname == "/pinned"
  const [pinValue, setPinValue] = useState(window.location.pathname == "/pinned" ? "Unpin" : "Pin");
  const [disablePinBtn, setDisablePinBtn] = useState(false);
  const matches = useMediaQuery('(min-width:1024px)');
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [showPinOption, setShowPinOption] = useState(props.value);
  const isRecent = window.location.pathname == "/recentfile";
  const isPinned = window.location.pathname == "/pinned";
  const isTagsPage = window.location.pathname.indexOf("tags-folder") != -1;
  const isPlaylist = window.location.pathname == "/playlist";
  const isViewVideo = window.location.pathname == "/ViewVideo";

  const location = useLocation()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    showMenu();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { downloadFile } = openDownload();
  const { pinUnpinHandler } = PinUnpin();


  const onHandlerDownloadOpen = (data) => {
    let matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
    if (props.isRecentFiles == true) {
      matchingData = matchingData.map(v => { return { ...v, id: v.mediaId } });
    }
    console.log("a1",{matchingData})
    downloadFile(data, matchingData[0], setOpenText, setDownloadText, dispatch);
    console.log("a1",downloadFile(data, matchingData[0], setOpenText, setDownloadText, dispatch))
    // downloadFile(data, methodsAndState.selectedId, setOpenText, setDownloadText);
  };


  React.useEffect(() => {
    setShowThreeDots(showMenu() != 0);
  }, [])

  React.useEffect(() => {
    if (userPermissionsListRedux.permissionList) {
      setUserPermissionsList(userPermissionsListRedux.permissionList)
      // setShowThreeDots(showMenu() != 0);
    }
  }, [userPermissionsListRedux])

  React.useEffect(() => {
    // console.log(props)
    setShowThreeDots(showMenu() != 0);
  }, [userPermissionsList])


  React.useEffect(() => {
    if (methodsAndState.selectedItems.size && methodsAndState.dashboardList) {
      const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
      setMatchingSelectedData(matchingData)
      if (matchingData.every(item => item.pinned === true) || window.location.pathname == "/pinned") {
        setPinValue('Unpin')
      } else {
        setPinValue('Pin')
      }
    }

  }, [methodsAndState.selectedItems])

  const onHandlerPinUnpin = (d) => {
    setDisablePinBtn(true);
    pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, d, methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList, setDisablePinBtn);
  }

  const buttonSX = {
    '&': { color: '#00000087' },
    '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
  }

  const showMenu = () => {
    let count = 0;

    if (userPermissionsList.length == 0) {
      return count;
    }

    if (window.location.pathname == "/trash" || window.location.pathname == "#/trash") {
      if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE])) {
        count++;
        // console.log("First", {count});
      }
      return count;
    }
    if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN])) {
      count += ((methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory')) ? 0 : matches ? 0 : process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd' ? 0 : 1);
      // console.log("Second", {count});
    }
    if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_EDIT])) {
      count += (methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0 : matches ? 0 : 1)
      // console.log("Third", {count});
    }
    if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_RENAME || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_RENAME])) {
      count += (methodsAndState.selectedItems.size > 1 ? 0 : matches ? 0 : 1)
      // console.log("Fourth", {count});
    }
    if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_MOVE])) {
      count += (methodsAndState.selectedItems.size > 1 || (window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile") || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? 0
        : 1)
      // console.log("Fifth", {count});
    }
    if (CheckPermission(userPermissionsList, [userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE])) {
      count += ((window.location.pathname == "/recentfile" || window.location.pathname == "#/recentfile") ? 0 : matches ? 0 : 1)
      // console.log("Sixth", {count});
    }
    // if(!props.value && (pinunpinshow || matchingSelectedData.every(item => item.pinned === false))){
    count += (!showPinOption ? (pinunpinshow || matchingSelectedData.every(item => item.pinned === false)) ? 1 : 0 : 0)
    // count++;
    // console.log("Seventh", {count, condition: (!showPinOption ? pinunpinshow || matchingSelectedData.every(item => item.pinned === false) ? 1 : 0 : 0), value: showPinOption, pinunpinshow, matchingSelectedData: matchingSelectedData.every(item => item.pinned === false), data: matchingSelectedData});
    // }
    // console.log({count})
    return count;
  }

  // const handleCopyLink = (url) => {
  //   console.log('a1', url)
  //   navigator.clipboard.writeText(url)
  //     .then(() => {
  //       console.log('a1', url);
  //       dispatch(openSnackbar({ dataLoad: true, message: 'Your link has been copied successfully.', severity: 'info' }));
  //       console.log('a1',dispatch(openSnackbar));
  //     })
  //     .catch((error) => {
  //       console.log('a1',error)
  //       setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
  //     });
  // };
  // const [selectedVideo, setSelectedVideo] = React.useState(location.state.currentVideo || location.state.playlist[0]);
  // console.log('a1',playlist)
  // const feedPostName = listRoutes.filter((route) => route.path == (searchParams['feedPostName'] ?? location.state?.feedPostName))?.[0]?.label ?? listRoutes.filter((route) => route.path == ((typeof searchParams['feedPostName'] != 'string') ? '/' : searchParams['feedPostName']) ?? searchParams['feedPostName'])?.[0]?.label ?? searchParams['feedPostName'];
  // console.log({ first: listRoutes.filter((route) => route.path == location.state?.feedPostName ?? searchParams['feedPostName'])?.[0]?.label, second: ((typeof searchParams['feedPostName'] != 'string') ? 'Home' : searchParams['feedPostName']), third: searchParams })
  // const indivisualAPI = searchParams['playlist'] ?? location.state?.indivisualAPI ?? 'FeedList';
  // const [videoListByCategory, setVideoListByCategory] = useState(location.state.playlist);


  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url)
      // console.log('a1', navigator.clipboard.writeText(url))
      .then(() => {
        console.log('a1', url);
        dispatch(openSnackbar({ dataLoad: true, message: 'Your link has been copied successfully.', severity: 'info' }));
        console.log('a1', dispatch(openSnackbar));
      })
      .catch((error) => {
        console.log('a1', error)
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: 'error' }));
        // setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  };

  const getCurrentIndivisualAPI = () => {
    let indivisualAPi = PageName.PlayList;
    switch(methodsAndState.page){
      case PageName.AllVideos : indivisualAPi = 'folderpermission'; break;
      case PageName.RecommendedVideos : indivisualAPi = 'recommendedVideo'; break;
      case PageName.PlayList :
      default : indivisualAPi = 'playlist';
    }
    return indivisualAPi;
  };

  const encodeUrl = () => {
    let keyIndex = methodsAndState.dashboardList.reduce((prev,curr,index) => {
      if(methodsAndState.selectedItems.has(curr.id)){
        prev = index;
      }
      return prev;
    }, 0)
    
    const queryStringObject = {
      currentVideoId: methodsAndState.dashboardList[keyIndex].id,
      // playlist: methodsAndState.dashboardList,
      page: methodsAndState.page,
      indivisualApi: getCurrentIndivisualAPI(),
      playlistId: methodsAndState?.playlistId ?? false
    };
    const encodedQueryString = encriptionHelper().encodeAndEncryptURLParams(queryStringObject);
    return `/ViewVideo?query=${encodedQueryString}`;
    // return `/ViewVideo/${selectedVideo.id}`;
  }
  

  return (
    <Box>
      {/* {showThreeDots ? "1" : "0"} */}
      {/* {showThreeDots != 0 && ( */}
      <IconButton
        sx={buttonSX}
        id="demo-customized-button"
        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {/* // )} */}

      {window.location.pathname == "/trash" ?
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          sx={{ color: 'red' }}
        >
          <MenuItem onClick={handleClose}>
            <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.restoreSelectedItems(props.data)} startIcon={<RestoreIcon />}>
              Restore
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('deletePermanentDialog')} startIcon={<DeleteIcon />}>
              Permanent Delete
            </Button></MenuItem>

        </Menu>
        : <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          sx={{ color: 'red' }}
        >
          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN]}
            yes={methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
              :
              // matches ? '' : 
              process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd' ? '' :
              <MenuItem onClick={handleClose}>
                <Button sx={buttonSX} variant="text" onClick={() => onHandlerDownloadOpen('open')} startIcon={<FileOpenIcon />}>
                  {openText}
                </Button>
              </MenuItem>}
          /> */}
          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_EDIT]}
            yes= */}
          {methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
            : !isViewVideo &&(
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => {console.log("Green Tea leave");methodsAndState.openDrawerPublish(methodsAndState.isRecommanded? 'editRecommandedFile' : 'editFile')}} startIcon={<InfoIcon />}>
                Edit
              </Button>
            </MenuItem>
  )
          }
          {/* /> */}
          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_OPEN]}
            yes= */}
          {(isPlaylist || window.location.pathname == "/trash" || methodsAndState.selectedItems.size > 1 || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory')) ?
            ""
            :
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => {console.log("Green Tea");onHandlerDownloadOpen('download');}} startIcon={<DownloadIcon />}>
                {downaloadText}
              </Button>
            </MenuItem>}
          {/* /> */}

          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_RENAME || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_RENAME]}
            yes= */}
          {!(isPlaylist || isViewVideo ) && (
            // matches ? '' :
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => {console.log("Green Tea");methodsAndState.openDialogPublish(methodsAndState.isRecommanded? 'renameRecommandedDialog' : 'renameDialog')}} startIcon={<DriveFileRenameOutlineIcon />}>
                Rename
              </Button>
            </MenuItem>)}


          {!isPlaylist && (
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" startIcon={<LinkOutlinedIcon />} onClick={() => {console.log("Green Tea");handleCopyLink(window.location.origin + encodeUrl())}}>
                Copy Link
              </Button>
            </MenuItem>
          )}
          {/* /> */}

          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_MOVE]}

            yes={methodsAndState.selectedItems.size > 1 || window.location.pathname == "/recentfile" || isPinned || isTagsPage || (matchingSelectedData.length && matchingSelectedData[0].type == 'directory') ? ""
              :
              <MenuItem onClick={handleClose}>

                <Button sx={buttonSX} variant="text" onClick={() => methodsAndState.openDialogPublish('moveDialog')}  startIcon={<KeyboardTabIcon />}>
                  Move to
                </Button>
                
              </MenuItem>}
          /> */}

          {/* <Can
            userPermissions={userPermissionsList}
            requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_DELETE || userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_DELETE]}
            yes= */}
          {window.location.pathname == "/recentfile" ? "" :
          !isViewVideo &&(
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => {console.log("Green Tea");methodsAndState.openDialogPublish(methodsAndState.isRecommanded? 'deleteRecommandedDialog' : 'deleteDialog')}} startIcon={<DeleteIcon />}>
                Delete
              </Button></MenuItem>)}
          {/* /> */}
          {/* {pinunpinshow || matchingSelectedData.every(item => item.pinned === false) ? */}
          {/* {pinunpinshow || matchingSelectedData.every(item => item.pinned === false) ?
            <MenuItem onClick={handleClose}>
              <Button sx={buttonSX} variant="text" onClick={() => onHandlerPinUnpin(pinValue)} startIcon={pinunpinshow ? <img src={unpin} /> : <PushPinIcon />}>
                {pinValue}
              </Button>
            </MenuItem> : null } */}
        </Menu>}
    </Box>
  );
}
export default MenuBar;