import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../features/dialogBox/dialogBoxSlice';
import ApiService from '../../../utils/ApiService';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import MyContext from '../Mycontext';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import checkboxHelper from '../checkboxHelper';
export default function TrashDeleteDialog() {
    const methodsAndState = React.useContext(MyContext);
    const [checkedValue, setCheckedValue] = useState(null)
    const [flag, setFlag] = useState(false);
    const userList = useSelector((state) => state?.user?.userListName);

    const dispatch = useDispatch();
    const closeDialogPublish = (id) => {
        dispatch(closeDialog(id));
    }
    const handleDeleteForever = () => {
        deleteHandler();
        closeDialogPublish('deleteDialog');
    };
    function deleteResponse(endPoint, pids, vids, matchingData) {
        let params = {
            pids,
            vids,
            userId: userList?.userId
        }
        // let checkedValue = checkboxHelper().snackBarMessageHandler(fileId, folderId)
        setFlag(true)
        ApiService.delete(endPoint, params).then(res => {
            setFlag(false)
            methodsAndState.setSelectedItems(new Set([]))
            methodsAndState.setSnackBar({ dataLoad: true, message: `${(pids.length ? 'Playlist': ' ') + (vids.length ? 'Video': '') + " has been successfully permanent deleted."}`, severity: "info" })
            let mergefolderfile = matchingData.map(v => v.id);
            let deleteData = methodsAndState.dashboardList.filter(v => !mergefolderfile.includes(v.id))
            methodsAndState.setDashboardList(deleteData)
            methodsAndState.setSelectedId({ id: '0', value: "", name: "", type: "" })
            closeDialogPublish('deleteDialog')
        }).catch((error) => {
            setFlag(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })

    }
    const deleteHandler = () => {
        if (methodsAndState.selectedId.delete == "all") {
            ApiService.delete('deleteTrash', {'userId': userList?.userId}).then(res => {
                methodsAndState.setDashboardList([])
                methodsAndState.setSelectedItems(new Set([]))
            })
        } else {
            if (checkedValue) {
                let pids = []
                let vids = []
                const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
                for (const item of matchingData) {
                    if (item.numVideos != undefined) {
                        pids.push(item.id);
                    } else {
                        vids.push(item.id);
                    }
                }
                deleteResponse('multiplefilefolderdelete', pids, vids, matchingData)
            }
        }
    }
    return (
        <Box pb="1rem">
            <DialogTitle id="alert-dialog-title">
                {"Delete Permanent"}
            </DialogTitle>
            <DialogContent sx={{ padding: '16px 24px' }}>
                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                    {/* {methodsAndState.dynamicDeleteMessage} */}
                    {methodsAndState.selectedId.delete == "all" ?
                        "All items in the trash will be deleted forever and you won’t be able to restore them."
                        : "This will be deleted forever and you won't be able to restore it."}
                </DialogContentText>
                <FormGroup sx={{ padding: '20px 0px 0px 0px' }}>
                    <FormControlLabel required
                        control={<Checkbox
                            onChange={(e) => setCheckedValue(e.target.checked)}
                            sx={{ mb: 3 }}
                        />}
                        label="I Understand that deleting Video from Tstream is permanent and cannot be undone." />
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button onClick={() => closeDialogPublish('deleteDialog')} disabled={!flag ? false : true}>
                    CANCEL
                </Button>

                {flag == false ? <Button onClick={handleDeleteForever} color='primary' variant="contained" disabled={checkedValue ? false : true} >YES, DELETE FOREVER</Button>
                    : <Button color='primary' variant="contained"> <CircularProgress color={'success'} size="20px" /></Button>}
            </DialogActions>
        </Box>
    );
}